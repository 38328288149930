import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from 'react-google-recaptcha';
import "../assets/css/pdfSighn.css";
import icon1 from "../static/assets/img/icons/icon-1.png";
import icon2 from "../static/assets/img/icons/icon-2.png";
import icon3 from "../static/assets/img/icons/icon-3.png";
import icon4 from "../static/assets/img/icons/icon-4.png";
import pdfSighnImage from "../static/image/img/pdfSignerBack.jpg";
import pdfImage from "../static/img/pdf 1.jpg";
import { Helmet } from 'react-helmet';
const PdfSighn = () => {
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "PDF Signer",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Unlock seamless document management with our PDF Signer tool, designed to simplify your workflow. Effortlessly sign multiple PDF files at once by selecting your input and output directories. Enhance your productivity and maintain document integrity with this intuitive solution that caters to both individual and professional needs. Streamline your signing process today!",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Unlock seamless document management with our PDF Signer tool, designed to simplify your workflow. Effortlessly sign multiple PDF files at once by selecting your input and output directories. Enhance your productivity and maintain document integrity with this intuitive solution that caters to both individual and professional needs. Streamline your signing process today!"
	}
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Taxosmart | PDF Signer</title>
        <meta charset="ISO-8859-1" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="The main function of PDF signer is to sign multiple PDF files by selecting input and output directories. Ideal for bulk signing of corporate documents, saving time and resources compared to traditional methods." />
        <meta name="keywords" content="PDF Signer, Digital Signature, PDF Signing Software, Bulk PDF Signer" />
        <meta name="author" content="Taxosmart" />

        {/* <!-- Open Graph meta tags for Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="PDF Signer - Bulk Signing Made Easy" />
        <meta property="og:description" content="Sign multiple PDF files effortlessly with our PDF signer software, designed for bulk signing of corporate documents. Save time and resources." />
        <meta property="og:image" content="/path/to/image.jpg" />
        <meta property="og:url" content="https://www.taxosmart.com/PdfSighn" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter meta tags --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://www.taxosmart.com/PdfSighn" />
        <meta name="twitter:title" content="PDF Signer - Bulk Signing Made Easy" />
        <meta name="twitter:description" content="Sign multiple PDF files effortlessly with our PDF signer software, designed for bulk signing of corporate documents. Save time and resources." />

        {/* <!-- Bing meta tags --> */}

        <meta name="robots" content="index, follow" />

        {/* <!-- Canonical link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/ItrNon" />



      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontents">
              <h1 className="InfoColor">PDF Signer</h1>
              <p className="effort">
                The main function of pdf signer is you can sign multiple pdf
                files by selecting input and output directory.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={pdfSighnImage}
                className="img-fluid gstImageCss pdfImage" 
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <div className="container-xxl py-5" id="maincontainer">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1
              className="gstFont display-5 mb-4"
              style={{ color: "#132f6b" }}
            >
              INFORMATION ABOUT PDF SIGNER
            </h1>
          </div>
        </div>
      </div>

      <section id="about" className="about" style={{ marginTop: "-7%" }}>
        <div className="container">
          <div className="row gx-0">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <div className="content">
                <h2 style={{ color: "#132f6b" }}>PDF Signer</h2>
                <p className="mt-5">
                  The main function of pdf signer is you can sign multiple pdf
                  files by selecting input and output directory. This is ideal
                  for bulk signing of a large number of corporate documents
                  rather than signing each one individually. don't lose precious
                  time and money on paper, postage, faxing, scanning or using
                  other document signing software that takes you hours to set up
                </p>
              </div>
            </div>

            <div className="col-lg-6 d-flex align-items-center">
              <img src={pdfImage} className="pdfImage" />
            </div>
          </div>
        </div>
      </section>

      <div className="container-xxl py-5" id="maincontainer">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp fadeCss"
            data-wow-delay="0.1s"
          >
            <h4 className="" style={{ color: "#5cb85c" }}>
              Benefits
            </h4>
            <h1
              className=" gstFont display-5 mb-4"
              style={{ color: "#132f6b" }}
            >
              What Are The Benefits Of PDF Signer
            </h1>
          </div>
          <div className="container-xxl py-5" id="maincontainer">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img
                          className="flex-shrink-0 mt-5"
                          src={icon1}
                          alt="Icon1"
                        />
                        <div className="ms-4 ">
                          <h3>They're fast </h3>
                          <p className="mb-0">
                            instead of waiting for documents to be sent by
                            courier around the globe, your contracts can be
                            completed and delivered with digital signatures in a
                            matter of seconds.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="ms-4">
                          <h3>They're secure</h3>
                          <p className="mb-0">
                            your contracts are even more secure because they're
                            not traveling around the globe to reach you ! you
                            don't have to meet people in person to sign a
                            document. also, if you receive a document that needs
                            to be signed it unburdens you.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="ms-4">
                          <h3>They are less risky</h3>
                          <p className="mb-0">
                            because digital signatures use secure platforms,
                            there's less risk of fraud, and you can be sure of
                            greater document security. it also helps to protect
                            the environment as the number of trees required to
                            produce paper significantly reduces as the use of
                            electronic signatures gradually becomes universal.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5" id="maincontainer">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp fadeCss"
            data-wow-delay="0.1s"
          >
            <h1 className="gstFont display-5 mb-4" style={{ color: "#132f6b" }}>
              Speciality of our PDF Signer
            </h1>
          </div>
          <div className="container-xxl py-5" id="maincontainer">
            <div className="container">
              <div className="row g-5">
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={icon1} alt="Icon" />
                        <div className="ms-4">
                          <h3>Sign PDFs and documents. </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={icon2} alt="Icon" />
                        <div className="ms-4">
                          <h3> High quality and free technical support.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div className="row g-4">
                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={icon3} alt="Icon" />
                        <div className="ms-4">
                          <h3> Support for multiple signatures and profile.</h3>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-flex align-items-start">
                        <img className="flex-shrink-0" src={icon4} alt="Icon" />
                        <div className="ms-4">
                          <h3>Sign digitally on any page of document. </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                PDF Signer
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={pdfSighnImage}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "PDF Signer")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfSighn;
