import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from "axios";
import "../../assets/css/style.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/navbar.css";
import SucWeb from "../../static/img/Succ.webp";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    service: '',
    comment: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/apiCustomerDetail/', formData)
      .then(response => {
        setSuccess('Registration successful!');
        setSuccessModalVisible(true);
        setError('');
        setFormData({
          type: 'ContactUs',
          name: '',
          email: '',
          mobile: '',
          service: '',
          comment: ''
        });
      })
      .catch(error => {
        setError('Error during registration: ' + error.message);
        setErrorModalVisible(true);
        setSuccess('');
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Contact Us - Taxosmart</title>
        <meta name="description" content="Get in touch with us for inquiries and support." />
        <meta name="keywords" content="contact, inquiries, support, Taxosmart" />
      </Helmet>

      <div>
        <div className="container-fluid page-header py-5 mb-5 wow fadeIn ng-scope" data-wow-delay="0.1s">
          <div className="container py-5">
            <h1 className="display-1 text-white fs-115 slideInDown">Contact Us</h1>
            <div className="d-flex"></div>
          </div>
        </div>

        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <h4 className="section-title text-end">Contact Us</h4>
                <h1 className="display-5 mb-4 fs-1">We'd love to hear from you!</h1>
                <div className="row g-4">
                  <div className="col-12">
                    <div className="d-flex info">
                      <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light w-65 h-65">
                        <i className="fa fa-2x fa-phone-alt"></i>
                      </div>
                      <div className="ms-4 expertInfo">
                        <p className="mb-2">TALK TO AN EXPERT</p>
                        <h3 className="mb-0">+91 9967 646 171</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex info">
                      <div className="d-flex flex-shrink-0 align-items-center justify-content-center bg-light">
                        <i className="fa fa-2x fa-envelope-open"></i>
                      </div>
                      <div className="ms-4 expertInfo">
                        <p className="mb-2">FOR QUERIES</p>
                        <h3 className="mb-0">info@taxosmart.com</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                <form onSubmit={handleSubmit} style={{ border: '0px solid black' }}>
                  <div className="row g-3">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.type = 'ContactUS'}
                      onChange={handleChange}
                      required
                      style={{ display: 'none' }}
                      placeholder="Your Name"
                    />
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder="Your Name"
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Email Id"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Mobile"
                        required
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                    <input
                        type="text"
                        className="form-control"
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        required
                        placeholder="Write Service"
                      />
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control"
                        rows="5"
                        name="comment"
                        placeholder="Message"
                        value={formData.comment}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <div className="col-12 subBtn">
                      <button className="btn btn-success w-100 py-3" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Success Modal */}
        <div>
          {isVisible && (
            <Modal show={successModalVisible} onHide={handleCloseSuccess}>
              <Modal.Header closeButton style={{ width: "100%" }}>
                <Modal.Title style={{ fontSize: "28px" }}>Successful</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Thank you for choosing Taxosmart. We request you to confirm your email address. Our representative will soon contact you.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePop}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>

        {/* Error Modal */}
        <div>
          {isVisible && (
            <Modal show={errorModalVisible} onHide={handleCloseError}>
              <Modal.Header closeButton style={{ width: "100%" }}>
                <Modal.Title style={{ fontSize: "28px" }}>Something Went Wrong!</Modal.Title>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePop}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ContactUs;
