import axios from "axios";
import React, { useState } from "react";

import "../assets/css/Accountingbookkpg.css";

import AccBook from "../static/image/img/accbook.jpg";
import Bookimg from "../static/assetse/img/bookkeeping.png";

import Bookpng from "../static/img/bookkeeping.png";
import ReCAPTCHA from "react-google-recaptcha";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const AccountingBookKpg = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Easily manage your Bookkeeping",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Looking for efficient bookkeeping services? TaxOSmart offers expert support for small businesses, helping you maintain accurate financial records and analyze transactions to reduce costs and taxes. Streamline your accounting with our network of CA/CSs and tax professionals.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Looking for efficient bookkeeping services? TaxOSmart offers expert support for small businesses, helping you maintain accurate financial records and analyze transactions to reduce costs and taxes. Streamline your accounting with our network of CA/CSs and tax professionals."
	}

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Bookkeeping</title>
        <meta charset="utf-8" />
        <meta name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description"
          content="Bookkeeping is a systematic record of financial transactions in the appropriate books of records. What it means is, whether you make a sale or purchase something, a record will be maintained of the transaction. This record will allow you to easily keep a track of all your transactions and will help you analyse how you can cut costs and reduce taxes. " />
        <meta name="keywords"
          content="bookkeeping and accounting, bookkeeping vs accounting, bookkeeping, accounting, accounting vs bookkeeping." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <main id="pageTop" className="pageTop">
        {/* copy code */}
        <section
          id="hero"
          className="hero d-flex align-items-center wow fadeIn top5"
          data-wow-delay="0.1s"
        >
          <div className="container top0">
            <div className="Topbox">
              <div className="topcontent">
                <h1 className="fs-1">Easily manage your Bookkeeping</h1>
                <p className="fs-5"> Simplifying your Accounting Process</p>
                <div data-aos-delay="600">
                  <div className="text-center text-lg-start" id="resp">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      data-bs-backdrop="false"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span className="getTouch">Contact Us</span>
                      <i className="bi bi-arrow-right white"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
                <img src={Bookpng} className="img-fluid gstImageCss" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* copy code  */}

        {/* <!-- End Call To Action Section --> */}
        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={Bookimg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>INFORMATION ABOUT BOOKKEEPING SERVICES</h3>
                <p className="text-justify">
                  Bookkeeping is a systematic record of financial transactions
                  in the appropriate books of records. What it means is, whether
                  you make a sale or purchase something, a record will be
                  maintained of the transaction. This record will allow you to
                  easily keep a track of all your transactions and will help you
                  analyse how you can cut costs and reduce taxes. Bookkeeping is
                  a function of the accounts department of a business. However,
                  many small businesses do not have fully staffed and efficient
                  accounting departments and require the help of external
                  bookkeeping services. TaxOSmart can help your business
                  maintain its books through its network of CA/CSs and tax
                  experts.
                </p>
                <p className="text-justify">
                  Bookkeeping services are necessary for all businesses to
                  ensure accurate operational / financial information. Such
                  information is required by Management, Regulators, and
                  Investors. Also, it is a legal requirement for any business to
                  maintain an appropriate book of accounts to ensure that all
                  relevant taxes are paid and tax filings are made on time.
                </p>
                <p></p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop">
          <div className="container">
            <header className="section-header">
              <h2 className="mediaFont">What Is Included In Our Package?</h2>
            </header>
            <br />

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Accounts payable function</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Accounts Reconciliation</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Payroll</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Monthly Analysis</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- / row --> */}
          </div>
        </section>
        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features featuresTop mt-0">
          <div className="container" data-aos="">
            <header className="section-header">
              <h2>Advantages Of Bookkeeping</h2>
            </header>
            <br />

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4">
                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Cost-Effective</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Reduced Tax Liabilities</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Time Effective</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Peace of Mind</h3>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Higher Profits</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
      
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- Modal --> */}

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Accounting Book Keeping
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Bookpng}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Accounting / Bookkeeping")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AccountingBookKpg;
