import React from "react";
import { Helmet } from "react-helmet";

import "../../assets/css/style.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/navbar.css";
import "../../assets/css/aboutUs.css";

import About2 from "../../static/assets/img/about-2.jpg";
import Taxosmartpng from "../../static/assets/img/Taxosmart.png";
import Icon2 from "../../static/assets/img/icons/icon-2.png";
import Icon3 from "../../static/assets/img/icons/icon-3.png";
import Icon4 from "../../static/assets/img/icons/icon-4.png";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>About Us - TaxOSmart</title>
        <meta name="description" content="Learn more about TaxOSmart, a technology platform dedicated to simplifying legal and business matters for startups and small businesses." />
        <meta name="keywords" content="TaxOSmart, about us, technology platform, legal compliance, business solutions" />
        <meta name="author" content="TaxOSmart Team" />
        <link rel="canonical" href="https://www.taxosmart.com/about" />
      </Helmet>

      <div className="container-xxl py-5 ng-scope">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h4 className=" fs-4">Reasons Why Customers Like Us</h4>
              <h1 className="display-5 mb-4 fs-3">Why You Should Trust Us? Learn More About Us!</h1>
              <p className="mb-4">
                TaxOSmart is founded on the principle of building and implementing great ideas that drive progress for clients and enhance lives through enterprise solutions.
              </p>
              <p className="mb-4">
                TaxOSmart.com is a technology platform to simplify legal and business-related matters. We are committed to helping startups and small business owners in solving legal compliance related to starting and running their business.
              </p>
              <p className="mb-4">
                Through technology, we bring numerous government/legal forms to one place and have simplified them to be fully understood by the common man. We are a technology-driven platform trying to organize the professional services industry in India.
              </p>
            </div>
            <div className="col-lg-7 wow fadeInUp" data-wow-delay="0.5s">
              <div className="feature-img">
                <img className="img-fluid mt-0" src={About2} alt="About Us" />
                <img className="img-fluid" src={Taxosmartpng} alt="TaxOSmart" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Facts Start --> */}
      <div className="container-xxl py-5">
        <div className="container pt-5">
          <div className="row g-4">
            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={Icon2} alt="Our Vision" />
                </div>
                <h3 className="mb-3">Our Vision</h3>
                <p className="mb-0 text-justify">
                  To be the most preferred technological platform for every client who encounters us, as they undergo calmness by leaving their hectic official and business matters to us.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="fact-item text-center bg-light h-100 p-5 pt-0">
                <div className="fact-icon">
                  <img src={Icon3} alt="Our Mission" />
                </div>
                <h3 className="mb-3">Our Mission</h3>
                <p className="mb-0 text-justify">
                  To offer the most affordable, quick, and automated professional services to our clients and provide them one-click access to all the processes like startup registrations, tax filing, accounting, documentation, finance accumulation, and many other legal affairs managed by a professional team that is dedicated to delivering their clients with satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Facts End --> */}

      {/* Features and Objectives Section (remains unchanged) */}

    </div>
  );
};

export default AboutUs;
