import axios from "axios";
import React, { useState } from "react";
import investmentProofImage from "../static/img/investmentPrrof.webp";
import "../assets/css/investmentProofCss.css";
import "../assets/css/bootstrap.min.css";
import "../static/assets/css/styleMin.css";
import "../static/assets/css/mainMin.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";

const InvestmentProof = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Information About GST Reconciliation Software",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/img/investmentPrrof.webp", 
		"articleBody": "Effortlessly reconcile GST transactions with precision and efficiency using our specialized software, ensuring compliance and accuracy for your business financial records.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/public/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Effortlessly reconcile GST transactions with precision and efficiency using our specialized software, ensuring compliance and accuracy for your business financial records.",
    "keyword" : "Investment Proof Software, Smart Investment Software"
	}

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("http://192.168.0.125:8080/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (


    <div>

<html>
    <body>
    <head>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>GST Invoice, Accounting And Inventory Management Software</title>
        <meta name="description" content="A GST invoice is a document that contains all relevant details of a business transaction and both parties involved. It must comprise the product name, description, quantity, supplier and purchaser details, terms of sale, rate charged, discounts, and more." />
        <meta name="keywords" content="GST Billing Software, Goods and Services Tax, GST Bill, Billing Software, GST software, GST App, GST solution, GST website, GST invoice." />
        <meta name="author" content="Taxosmart" />

        {/* <!-- Open Graph (for Facebook) --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="GST Invoice, Accounting And Inventory Management Software" />
        <meta property="og:description" content="Discover a comprehensive GST invoice solution that simplifies your billing process while ensuring compliance with GST regulations." />
        <meta property="og:url" content="https://www.taxosmart.com/gstInvoice" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter Card --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="GST Invoice, Accounting And Inventory Management Software" />
        <meta name="twitter:description" content="Discover a comprehensive GST invoice solution that simplifies your billing process while ensuring compliance with GST regulations." />
        <meta name="twitter:url" content="https://www.taxosmart.com/gstInvoice" />

        {/* <!-- Canonical Link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/gstInvoice" />

        {/* <!-- Bing and other robots --> */}
        <meta name="robots" content="index, follow" />

      </Helmet>
      </head>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="InfoColor">
                Information About GST Reconciliation Software
              </h1>
              <p className="effort">
                Effortlessly reconcile GST transactions with precision and
                efficiency using our specialized software, ensuring compliance
                and accuracy for your business financial records.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={investmentProofImage}
                className="img-fluid investmentProofImg" style={{ width: '100%' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="about" class="about ">
        <div class="container-fluied" data-aos="">
          <div class="row gx-0">
            <div
              class="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div class="container content topHead">
                <h1 class="display-1  slideInDown investmentFontSize fs-3">
                  What Is Investment Proof Verification System ?
                </h1>
                <br />
                <p class="topHead text-justify">
                  Tax-O-smart launches Online Investment Proof Verification
                  System to help companies get rid of the tedious process of
                  collecting proof of investment from employees manually
                  Employees can then log in to the system and upload the
                  documents which the employer can review, accept or reject
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="features"
        class="features featuresKey topHeadKey keyTop investTops "
      >
        <div class="container aos-init aos-animate" data-aos="">
          <h1 class="display-1   slideInDown keyFeaCss fs-3">Key Features</h1>
          <br />

          <div class="row keyTopFe">
            <div class="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
              <div class="row align-self-center gy-4 fade show active">
                <div
                  class="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Online submission of documents</h3>
                  </div>
                </div>

                <div
                  class="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Document preview appears in a single screen</h3>
                  </div>
                </div>

                <div
                  class="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Tagging of documents under respective investment
                      categories for easy verification.
                    </h3>
                  </div>
                </div>

                <div
                  class="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Generate Form 12BB in a single click</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" class="features worksCss">
        <div class="container aos-init aos-animate" data-aos="">
          <h1 class="display-1 slideInDown how fs-3">How It Works</h1>
          <br />
          <div class="row">
            <div class="col-lg-12 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4 worksTop">
                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Employee receives an email for submission of investment
                      proof
                    </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>Employees upload the investment proofs</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Verifier reviews the documents of investment and
                      accept/rejects the submission.
                    </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      A notification email is sent to the employees on
                      completion of review process.
                    </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>
                      Export all the information in excel and import it in your
                      payroll system
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                GST Reconciliation Software
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={investmentProofImage}
                  style={{ width: "70%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Investment Proof")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header closeButton style={{ width: "100%" }}>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Helmet>
  <script type="application/ld+json">
    {JSON.stringify(structuredData)}
  </script>
</Helmet>
</body>
</html>
    </div>
  );
};
export default InvestmentProof;
