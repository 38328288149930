import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Blogs = () => {
 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    type: 'TDS' // Set default type value
  });
  const [data, setData] = useState([]); // Initialize as an empty array
  const [img, setImg] = useState([]); // Initialize as an empty array
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get('/apiblogs/homeBlogs');
      // console.log('Success:',response.data); // Log the entire response data
      const result = response.data; // Assuming JSON is the property containing the array
      console.log("result ", result);
      setData(result.listTopics);
      setImg(result.listTopicImages);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('YOUR_API_ENDPOINT', formData);
      setSuccess('Form submitted successfully!');
      setSuccessModalVisible(true);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

 

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Render component
  // if (error) return <p>Error: {error}</p>;
  if (data.length == 0) return <p></p>; // Display loading state

  const combinedData = data.map(d => {
    const imgItem = img.find(i => i.topicId === d.id);
    return imgItem ? { ...d, ...imgItem } : d;
  });

  return (
 <div>

<div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: '600px', visibility: 'visible' , animationDelay: '0.1s' , animationName: 'fadeInUp'}}>
                <h4 class="" style={{color: '#5cb85c'}}>Blogs</h4>
                <h1 class="display-5 mb-4" style={{color:'#132f6b'}}>INSIGHTS & NEWSLETTERS</h1>
            </div>


    <div className="container">
    



      <div className='row d-flex'>
      { combinedData.map (blog => (
                <div class="card mb-4 col-md-4" key={blog.id}>
            <a href="#!"><img  src={`data:image/png;base64,${blog.image}`} class="card-img-top" style={{width:'100%'}} alt="..." /></a>
            <div class="card-body">
              <div class="small text-muted">{blog.date}</div>
              <h2 class="card-title h4">{blog.heading}</h2>
              {/* <p class="card-text">{item.description}</p> */}
              <a class="btn btn-primary" href={`/BlogDetail/${blog.topicId}`} >Read more →</a>
            </div>
          </div>  ))}
          

        
          
          </div>


      
          </div>
    </div>
  );
};

export default Blogs;
