import axios from "axios";
import React, { useState } from "react";
import "../assets/css/bulkPan.css";
import bulkPanImage from "../static/img/aadhaar-card_status.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha'
import { Helmet } from 'react-helmet';

const BulkPan = () => {


  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Bulk PAN-Aadhaar Status",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Ensure compliance with Rule 114AAA of the Income Tax Rules, 1962. As of March 31, 2021, failure to link your Permanent Account Number (PAN) to Aadhaar will render it inoperative, resulting in the presumption that you have not furnished your PAN. Stay informed to avoid penalties and maintain your financial integrity.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Ensure compliance with Rule 114AAA of the Income Tax Rules, 1962. As of March 31, 2021, failure to link your Permanent Account Number (PAN) to Aadhaar will render it inoperative, resulting in the presumption that you have not furnished your PAN. Stay informed to avoid penalties and maintain your financial integrity."
	}

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Bulk PAN Aadhaar</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="As per Rule 114AAA of Income Tax Rules, 1962, the PAN of a taxpayer shall become inoperative if it is not linked to Aadhaar on or before 31st March 2021, and it shall be deemed that they have not furnished the PAN." />
        <meta name="keywords" content="Bulk PAN-Aadhaar Status, PAN-Aadhaar Status, Bulk PAN-Aadhaar" />
        <meta name="author" content="Taxosmart" />

        {/* <!-- Open Graph (for Facebook) --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Bulk PAN Aadhaar" />
        <meta property="og:description" content="As per Rule 114AAA of Income Tax Rules, 1962, the PAN of a taxpayer shall become inoperative if it is not linked to Aadhaar on or before 31st March 2021." />
        <meta property="og:url" content="https://www.taxosmart.com/bulkPanAadhaar" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter Card --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Bulk PAN Aadhaar" />
        <meta name="twitter:description" content="As per Rule 114AAA of Income Tax Rules, 1962, the PAN of a taxpayer shall become inoperative if it is not linked to Aadhaar on or before 31st March 2021." />
        <meta name="twitter:url" content="https://www.taxosmart.com/bulkPanAadhaar" />

        {/* <!-- Canonical Link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/bulkPanAadhaar" />

        {/* <!-- Bing and other robots --> */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="InfoColor fs-3">Bulk PAN-Aadhaar Status</h1>
              <p className="effort">
                As per Rule 114AAA of Income tax Rules, 1962 PAN of taxpayer
                shall become inoperative if it is not linked to Aadhaar on or
                before 31st March 2021 and it shall be deemed that he has not
                furnished the PAN.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={bulkPanImage}
                className="img-fluid bulkPanImageCss"
                style={{ width: "100%" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="about" style={{ marginTop: "0%" }}>
          <div className="container-fluied">
            <div className="row gx-0">
              <div
                className="col-lg-12 d-flex flex-column justify-content-center"
                data-aos=""
                data-aos-delay="200"
              >
                <div className="container content">
                  <h1
                    className="  slideInDown fs-2"
                    style={{ color: "#0f316d", textAlign: "center" }}
                  >
                    Bulk PAN-Aadhaar Status
                  </h1>
                  <br />

                  <p className="decTop">
                    As per Rule 114AAA of Income tax Rules, 1962 PAN of taxpayer
                    shall become inoperative if it is not linked to Aadhaar on
                    or before 31st March 2021 and it shall be deemed that he has
                    not furnished the PAN
                  </p>
                  <ul>
                    <li>
                      Pursuant to this, he shall be liable for all the
                      consequences under the Income tax Act, 1961 for not
                      furnishing the PAN and the person (deductor) making any
                      payment to such individual will have to withhold tax @ 20
                      percent under section 206AA of the Act
                    </li>
                    <li>
                      This amendment requires that every person should verify
                      the PAN-Aadhaar linking of its vendors, customers,
                      employees, etc. before making payments to individuals
                    </li>
                    <li>
                      Income-tax department has released a facility to verify
                      the PAN-Aadhaar linking.However, only one record i'e PAN-
                      Aadhaar linking can be verified at a time. Bulk
                      verification facility is not available
                    </li>
                  </ul>

                  <p>You need this service, if</p>
                  <ul>
                    <li>You are making payment to resident individuals</li>
                    <li>You are withholding tax below rate of 20 percent</li>
                    <li>
                      Verification of PAN-Aadhaar linking of the vendors,
                      customers, employees, etc. is not done
                    </li>
                    <li>
                      You are dealing with verification of voluminous records of
                      PAN - Aadhaar linking
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="features"
          className="features featuresTopService whatTop"
          style={{ marginTop: "-0%" }}
        >
          <div className="container" data-aos="">
            <h1
              className=" slideInDown fs-2"
              style={{ color: "#0f316d", textAlign: "center" }}
            >
              What is our service propositions?
            </h1>
            <br />

            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4 decTop">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="200"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Bulk PAN-Aadhaar linking verification services</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        A dashboard providing birds-eye view along with detailed
                        reports
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="features"
          className="features"
          style={{ marginTop: "-0%" }}
        >
          <div className="container" data-aos="">
            <h1
              className="   slideInDown fs-2"
              style={{ color: "#0f316d", textAlign: "center" }}
            >
              Key potential benefits at a glance:
            </h1>
            <br />
            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0 d-flex whyRecon">
                <div className="row align-self-center gy-4 decTop">
                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="700"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Hosted on secured environment with adequate data
                        security measures
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>
                        Data received, stored and shared in password protected
                        Excel file
                      </h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Compliance with Aadhaar guidlines</h3>
                    </div>
                  </div>

                  <div
                    className="col-md-6"
                    data-aos="zoom-out"
                    data-aos-delay="400"
                  >
                    <div className="feature-box d-flex align-items-center">
                      <i className="bi bi-check"></i>
                      <h3>Result in substantial saving of time and efforts</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Success Modal */}

        <div>
          {isVisible && (
            <Modal show={successModalVisible} onHide={handleCloseSuccess}>
              <Modal.Header>
                <Modal.Title style={{ fontSize: "28px" }}>
                  Successfull
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Thank you for choosing Taxosmart,We Request you to confirm your
                email address. Our representative will soon contact you.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closePop}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>

        {/* Error Modal */}
        <div>
          {isVisible && (
            <Modal
              show={errorModalVisible}
              onHide={handleCloseError}
              style={{ width: "100%" }}
            >
              <Modal.Header>
                <Modal.Title style={{ fontSize: "28px" }}>
                  Something Went Wrong !
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>{error}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" id="closeBtn" onClick={closePop}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>

        <div className="modal fade" id="demoModal">
          <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
            <div className="modal-content sub-bg ">
              <div
                className="modal-header subs-header modal-xxl"
                style={{ width: "100%" }}
              >
                <h3 className="modal-title" style={{ fontSize: "34px" }}>
                  Bulk PAN-Aadhaar Status
                </h3>
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={bulkPanImage}
                    style={{ width: "70%", height: "auto", marginTop: "-2%" }}
                  />
                </div>
                <div className="heading-text text-center ">
                  <h4 className="shadow p-3 mb-5">
                    Ideal for Start-ups Going for Funding & Growing Business
                  </h4>
                </div>
                <div className="">
                  <form
                    onSubmit={handleSubmit}
                    style={{ border: "0px solid black" }}
                  >
                    <div className="form-group">
                      <input
                        className="form-control"
                        style={{ display: "none" }}
                        type="text"
                        id="type"
                        name="type"
                        value={(formData.type = "Bulk PAN-Aadhaar")}
                        onChange={handleChange}
                        required
                      />
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mobile">Mobile</label>
                      <input
                        type="mobile"
                        id="mobile"
                        name="mobile"
                        placeholder="Mobile"
                        className="form-control"
                        value={formData.mobile}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group" style={{ marginTop: '20px' }}>
                      <ReCAPTCHA
                        sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                        onChange={handleCaptchaChange} />
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '3%' }}>
                      <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default BulkPan;
