import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format, parseISO } from 'date-fns'; // Import date-fns
import '../assets/css/importantDates.css'

const ImportantDates =() =>{
	
    const [impDatesIncomeTax, setimpDatesIncomeTax] = useState([]);
    const [impDatesGST, setimpDatesGST] = useState([]);
    const [impDatesTDS, setimpDatesTDS] = useState([]);
    const [impDatesESI, setimpDatesESI] = useState([]);
    const [impDatesProvidentFund, setimpDatesProvidentFund] = useState([]);
    const [impDatesOthers, setimpDatesOthers] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [formData, setFormData] = useState([]);

    // Fetch data from API
    const fetchData = async () => {
        try {
            const response = await axios.get('/apiimportantDates/list/count');
            const result = response.data;

            setimpDatesIncomeTax(result.impDatesIncomeTax);
            setimpDatesGST(result.impDatesGST);
            setimpDatesTDS(result.impDatesTDS);
            setimpDatesESI(result.impDatesESI);
            setimpDatesProvidentFund(result.impDatesProvidentFund);
            setimpDatesOthers(result.impDatesOthers);
        } catch (error) {
            setError(error.message);
            setErrorModalVisible(true);
        }
    };


    // Handle form field changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('YOUR_API_ENDPOINT', formData);
            setSuccess('Form submitted successfully!');
            setSuccessModalVisible(true);
        } catch (error) {
            setError(error.message);
            setErrorModalVisible(true);
        }
    };

    // Close modals
    const handleCloseSuccess = () => setSuccessModalVisible(false);
    const handleCloseError = () => setErrorModalVisible(false);

    // Fetch data on component mount
    useEffect(() => {
        fetchData();
    }, []); // Empty dependency array ensures this runs once on mount

    // Combine all arrays into one
    const combinedData = [
        ...impDatesIncomeTax,
        ...impDatesGST,
        ...impDatesTDS,
        ...impDatesESI,
        ...impDatesProvidentFund,
        ...impDatesOthers,
    ];

    // Function to format date


    const formatDate = (dateString) => {
        console.log('Date String:', dateString); // Log the raw date string
        try {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                throw new Error('Invalid date');
            }
            return format(date, 'dd-MM-yyyy');
        } catch (error) {
            console.error('Error parsing date:', error);
            return 'Invalid date';
        }
    };
    
   return(
  <div>
    {/* <div className="container-fluid page-header py-5 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <h1 className="display-1 text-white animated slideInDown">Important Tax Deadlines and Dates</h1>
            <div className="d-flex">
          
           </div>
        </div>
    </div> */}

<section id="call-to-action" class="call-to-action"><div class="container" data-aos="zoom-out"><div class="row justify-content-center"><div class="col-lg-8 text-center"><h1 style={{color:'white'}}>Important Tax Deadlines and Dates</h1></div></div></div></section>
<br></br><br></br>
    <div className="row">
		<div className="container-fluid" style={{marginLeft:'4%' , marginRight:'4%'}}>
			<div className="col-md-12">
				<h1 style={{fontWeight: 'bold'}}>Important Tax Deadlines and Dates
				</h1>
				<hr />
				<p className="article-overview-header" style={{marginTop: '-18px'}}>OVERVIEW</p>
				<p>Knowing when and what you have to file can save you a lot of
					headaches at tax time. To avoid paying penalties, mark your
					calendar with the following important tax deadlines.</p>
			</div>
			<hr/>


		</div>
	</div>

    
	{/* <div className="row" id="collapseExample4"
		style={{marginLeft: '5%' , marginRight: '4%'}}>


		<div className="col-md-3">
			<label>From Date.</label> <input type="date"
				ng-model="hctr.search.fromDate"
				ng-change="hctr.search.fromDate=hctr.date(hctr.search.fromDate);"
				className="form-control" placeholder="From Date" />
		</div>
		<div className="col-md-3">
			<label>To Date</label> <input type="date"
				ng-model="hctr.search.toDate"
				ng-change="hctr.search.toDate=hctr.date(hctr.search.toDate);"
				className="form-control" placeholder="To Date" style={{size: '15px'}} />
		</div>


		<div className="row" id="search" style={{marginTop: '20px'}}>
			<div className="col-md-3" id="search">
				<button className="btn btns btn-success"
					ng-click="hctr.searchEntities(true,'importantDates')">Search</button>
			</div>
		</div>


	</div> */}



    <div className="container" style={{marginLeft: '5%', marginRight: '5%' ,marginTop:'1%'}}>
		<div className="row">
			<div className="col-md-12">


				<div className="tabbable-panel">
					<div className="tabbable-line">
						<ul className="nav nav-tabs " style={{display: 'inline-block'}}>
							<li className="active"><a data-target="#tab_default_1"
								data-toggle="tab"> GST </a></li>


							<li><a data-target="#tab_default_2" data-toggle="tab">
									TDS</a></li>
							<li><a data-target="#tab_default_3" data-toggle="tab">
									IncomeTax</a></li>
							<li><a data-target="#tab_default_4" data-toggle="tab">
									ESI</a></li>
							<li><a data-target="#tab_default_5" data-toggle="tab">
									ProvidentFund</a></li>
							<li><a data-target="#tab_default_6" data-toggle="tab">Others</a></li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane active" id="tab_default_1">
								<h3>Due Dates for Filing GST Returns</h3>
								<div className="row">

								<table className=" table table-bordered table-hover table-striped"  style={{fontSize: '15px', width: '99%', padding: '5px' , margin: '5px' ,borderRadius: '2px'}}>
										
										<thead role="rowgroup">
											<tr role="row"
												style={{height:'35px' , backgroundColor: '#0F316D' , color:'#fff' , textAlign: 'center'}}>
												<th style={{width:'6%'}}>Sr. No.</th>
												<th>Dates</th>
												<th>Description</th>
											</tr>
										</thead>
										<tbody>
										{impDatesIncomeTax.map((item, index) => (<tr key={index}>
												<td>{index+1}</td>
												<td>{item.duedate}</td>
												<td>{item.description}  </td>
											</tr>))}

										</tbody>
									</table>

								</div>
							</div>

							<div className="tab-pane" id="tab_default_2">
								<h3>TDS Payment Due Date for Every Quarter</h3>
								<div className="row">

									<table className=" table table-bordered table-hover table-striped"
										role="table"
										style={{height:'35px' , backgroundColor: '#0F316D' , color:'#fff' , textAlign: 'center'}}>
										
										<thead role="rowgroup">
										<tr role="row"
												style={{height:'35px' , backgroundColor: '#0F316D' , color:'#fff' , textAlign: 'center'}}>
												<th style={{width:'6%'}}>Sr. No.</th>
												<th>Dates</th>
												<th>Description</th>
											</tr>
										</thead>
										<tbody>
										{impDatesGST.map((item, index) => (<tr key={index}>
												<td>{index+1}</td>
												<td>{item.duedate}</td>
												<td>{item.description}  </td>
											</tr>))}

										</tbody>
									</table>

								</div>
							</div>
							<div className="tab-pane" id="tab_default_3">
								<h3>Due Dates for Filing Income Tax Returns</h3>
								<div className="row">

									<table className=" table table-bordered table-hover table-striped"
										role="table"
										style={{fontS: '15px' , width:'99%', padding: '5px', margin: '5px'}}>
										
										<thead role="rowgroup">
											<tr role="row"
												style={{height: '35px', backgroundColor: '#0F316D' , color:'#fff', textAlign: 'center'}}>
												<th style={{width:'6%'}}>Sr. No.</th>
												<th>Dates</th>
												<th >Description</th>
											</tr>
										</thead>
										<tbody>
										{impDatesTDS.map((item, index) => (<tr key={index}>
												<td>{index+1}</td>
												<td>{item.duedate}</td>
												<td>{item.description}  </td>
											</tr>))}

										</tbody>
									</table>

								</div>
							</div>
							<div className="tab-pane" id="tab_default_4">
								<h3>Due Dates for ESI</h3>
								<div className="row">

									<table className=" table table-bordered table-hover table-striped"
										role="table"
										style={{fontS: '15px' , width:'99%', padding: '5px', margin: '5px'}}>
										<thead>
										<tr role="row"
												style={{height: '35px', backgroundColor: '#0F316D' , color:'#fff', textAlign: 'center'}}>
												<th style={{width:'6%'}}>Sr. No.</th>
												<th>Dates</th>
												<th>Description</th>

											</tr>
										</thead>
										<tbody>
										{impDatesESI.map((item, index) => (<tr key={index}>
												<td>{index+1}</td>
												<td>{item.duedate}</td>
												<td>{item.description}  </td>
											</tr>))}

										</tbody>
									</table>

								</div>
							</div>
							<div className="tab-pane" id="tab_default_5">
								<h3>Due Dates for Provident Fund</h3>
								<div className="row">

									<table className=" table table-bordered table-hover table-striped"
										role="table"
										style={{fontS: '15px' , width:'99%', padding: '5px', margin: '5px'}}>
										
										<thead role="rowgroup">
											<tr role="row"
												style={{height:'35px',backgroundColor: '#0F316D' ,color:'#fff', textAlign: 'center'}}>
												<th style={{width: '6%'}}>Sr. No.</th>
												<th>Dates</th>
												<th>Description</th>

											</tr>
										</thead>
										<tbody>
										{impDatesProvidentFund.map((item, index) => (<tr key={index}>
												<td>{index+1}</td>
												<td>{item.duedate}</td>
												<td>{item.description}  </td>
											</tr>))}

										</tbody>
									</table>

								</div>
							</div>
							<div className="tab-pane" id="tab_default_6">
								<h3>Other Due Dates</h3>
								<div className="row">

									<table className=" table table-bordered table-hover table-striped"
										role="table"
										style={{height:'35px',backgroundColor: '#0F316D' ,color:'#fff', textAlign: 'center'}}>
										
										<thead role="rowgroup">
											<tr role="row"
												style={{height: '35px' , backgroundColor: '#0F316D' , color:'#fff' , textAlign: 'center'}}>
												<th>Sr. No.</th>
												<th>Dates</th>
												<th>Description</th>

											</tr>
										</thead>
										<tbody>
										{impDatesOthers.map((item, index) => (<tr key={index}>
												<td>{index+1}</td>
												<td>{item.duedate}</td>
												<td>{item.description}  </td>
											</tr>))}

										</tbody>
									</table>

								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>


  </div>

   )

}

export default ImportantDates;