import axios from "axios";
import React, { useState } from "react";

import "../assets/css/Section8reg.css";

import Nidhijpg from "../static/assetse/img/nidhiCompany.jpg";

import Nidhijpg2 from "../static/image/img/nidhiCompany.jpg";

import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';


const NidhiCRegisrtation = () => {
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Nidhi Company Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Nidhi refers to a type of financial institution in India focused on promoting savings and providing loans among its members. These companies are dedicated to fostering a culture of thrift while offering mutual benefits through deposits and lending services. Discover how Nidhi companies support financial well-being within communities.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "refers to a type of financial institution in India focused on promoting savings and providing loans among its members. These companies are dedicated to fostering a culture of thrift while offering mutual benefits through deposits and lending services. Discover how Nidhi companies support financial well-being within communities."
	}
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>Nidhi Company Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Nidhi Company Registration focuses on promoting thrift and savings among its members. This company receives deposits and lends money exclusively for mutual benefit. Explore our comprehensive package, including DIN for 3 directors, government registration fees, application preparation, and MSME certificate." />
        <meta name="keywords" content="Nidhi company registration, finance company, thrift, mutual benefit, DIN, MSME certificate, company formation." />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn top5"
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox">
            <div className="topcontent">
              <h1
                className="display-1 animated slideInDown fs-1"
                style={{ color: "#0f316d" }}
              >
                Nidhi Company Registration
              </h1>
              <p>Ideal for Lending and Borrowing Amongst Members</p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img src={Nidhijpg} className="img-fluid gstImageCss" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Call To Action Section --> */}

      {/* <!-- ======= Featured Services Section ======= --> */}
      <section id="featured-services" className="featured-services pb-0">
        <div className="container">
          <div className="section-header">
            <h2>Factors to Consider in Nidhi Company Name Selection</h2>
          </div>
          <br />

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className=" fa fa-solid  fa fa-truck"></i>
              </div>
              <div>
                <h4 >Application of DSC & DPIN:</h4>
                <p className="description text-justify">
                  First of all, the partners have to apply for Digital signature
                  and DPIN for Nidhi company. Digital signature is an online
                  signature used of filing and DPIN refer to Directors PIN
                  number issued by MCA. If the directors already have DSC and
                  DPIN, then this step can be skipped
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-leaf" aria-hidden="true"></i>
              </div>
              <div>
                <h4 >Name Approval:</h4>
                <p className="description text-justify">
                  You need to provide 3 different options for your company name
                  to MCA of which one will be selected. Names provided should
                  ideally be unique and suggestive of company business
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-4 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-id-card" aria-hidden="true"></i>
              </div>
              <div>
                <h4 >MOA & AOA submission:</h4>
                <p className="description text-justify">
                  Once name is approved, one needs to draft Memorandum of
                  Association and Articles of Association. Both MOA and AOA are
                  filed with the MCA with the subscription statement.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Featured Services Section --> */}
      <br />
      <br />

      {/* <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={Nidhijpg} className="img-fluid" alt="" />
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <div className="section-header">
                <h2>Nidhi Company Registration</h2>
              </div>
              <p className="text-justify">
                "Nidhi" is a Hindi word, which means finance or fund. Nidhi
                means a company which has been incorporated with the objective
                of developing the habit of thrift and reserve funds amongst its
                members and also receiving deposits and lending to its members
                only for their mutual benefit.
              </p>

              <ul>
                <li>
                  <i className="bi bi-diagram-3"></i>
                  <div>
                    <h5 className="fs-3">What Is Included In Our Package?</h5>
                    <p className="text-justify">
                      DIN For 3 Directors, Government Registration Fees ,
                      Application Preparation, Obtain MSME Certificate
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section -->

<!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0 stepTop">
        <div className="container">
          <div className="section-header mt-0 pt-0">
            <h2>Steps to Incorporate Nidhi Company Registration</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Application of DSC & DPIN:
                  </a>
                </h3>
                <p className="text-justify">
                  First of all, the partners have to apply for Digital signature
                  and DPIN for Nidhi company. Digital signature is an online
                  signature used of filing and DPIN refer to Directors PIN
                  number issued by MCA. If the directors already have DSC and
                  DPIN, then this step can be skipped.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Name Approval:
                  </a>
                </h3>
                <p className="text-justify">
                  You need to provide 3 different options for your company name
                  to MCA of which one will be selected. Names provided should
                  ideally be unique and suggestive of company busines
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    MOA & AOA submission:
                  </a>
                </h3>
                <p className="text-justify">
                  Once name is approved, one needs to draft Memorandum of
                  Association and Articles of Association. Both MOA and AOA are
                  filed with the MCA with the subscription statement.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/trucking-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Get Incorporation Certificate:
                  </a>
                </h3>
                <p className="text-justify">
                  It typically takes 15- 25 days to form a Private limited
                  company and get the incorporation certificate. Incorporation
                  certification is a proof that company has been created. It
                  also includes your CIN number
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/packaging-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    Apply for PAN, TAN and Bank account:
                  </a>
                </h3>
                <p className="text-justify">
                  Then you need to apply for PAN and TAN. PAN and TAN are
                  received in 7 working days. Post this, you can submit the
                  Incorporation certificate, MOA, AOA and PAN with a bank to
                  open your bank account.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      <br />
      <br />
      <br />
      <br />
      

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Nidhi Compnay Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Nidhijpg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Nidhi Company Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true">
                    Somthing Went Wrong
                  </i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NidhiCRegisrtation;
