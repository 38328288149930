import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/css/IsoRegistration.css";
import IsoImg from "../static/img/isoImage.webp";
import IsoImgMain from "../static/assetse/img/isoImage.png";
import PubPri from "../static/assetse/img/publicPrivate.jpg";
import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

const IsoRegistration = () => {

  
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "ISO Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover how ISO (International Organization for Standardization) promotes innovation and trade through established standards like ISO 9001, 22000, and 14000. Achieving ISO certification enhances your business's credibility and boosts opportunities for growth. Learn more about the benefits of ISO certification for your organization.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover how ISO (International Organization for Standardization) promotes innovation and trade through established standards like ISO 9001, 22000, and 14000. Achieving ISO certification enhances your business's credibility and boosts opportunities for growth. Learn more about the benefits of ISO certification for your organization."
	}
  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    

    <div>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="Topbox">
            <div className="topcontents">
              <h1 className="fs-1">ISO Registration</h1>
              <p>Ideal for Maintaining Standard and Quality.</p>
              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start" id="resp">
                  <a
                    href=""
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={IsoImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <br />
      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 content order-last  order-lg-first">
              <div className="section-header">
                <h2>ISO Registration</h2>
              </div>
              <p>
                ISO is an independent international organisation that
                facilitates innovation and leads way to the development of
                trade. The objective of ISO is to advance the improvement of
                standardization in technology. ISO has listed internationally
                recognised standards (e.g, 9001, 22000, 14000) to encourage
                worldwide exchange of goods. ISO registration or ISO
                certification improves the credibility of your business and
                helps you get more business. You might have seen ISO 9001
                Quality mark on products from large companies. This can be
                attained through ISO certification.
              </p>
              <p>
                Companies looking to obtain ISO certification, ISO registration
                or ISO certificates including ISO 9001, ISO 14001, OHSAS 18001,
                ISO 20000, ISO 27001, ISO 50001, ISO TS 16949 etc. certification
                in India can contact TaxOSmart. TaxOSmart has a team of
                certified lead auditors and engineers who are having more than
                7+ years experience in implementation of various ISO standards.
                ISO Registration can be done through TaxOSmart in Delhi NCR,
                Mumbai, Bengaluru, Chennai & all other Indian cities.
              </p>
            </div>

            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={IsoImgMain} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      {/* <!-- ======= Features Section ======= --> */}
      <section id="features" className="features whatTop">
        <div className="container">
          <div className="section-header">
            <h2>What Is Included In Our Package?</h2>
          </div>

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4">
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Consultation</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Application Drafting</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Drafting of policy Standards</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Certificate Issue</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End Features Section --> */}

      <br />

      {/* <!-- ======= Featured Services Section ======= --> */}
      <section id="featured-services" className="featured-services isoTop">
        <div className="container">
          <div className="section-header">
            <h2>Advantages Of ISO Certification</h2>
            <p>
              There are multiple benefits of ISO registration. Some of the key
              benefits have been outlined below:
            </p>
          </div>

          <br />
          <div className="row gy-4">
            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-briefcase"></i>
              </div>
              <div>
                <h4 >Get government tenders:</h4>
                <p className="description">
                  ISO certificate is now required in a majority of government
                  tenders.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-solid  fa fa-truck"></i>
              </div>
              <div>
                <h4 >Build credibility internationally:</h4>
                <p className="description">
                  ISO certification helps your organisation helps credibility to
                  build overseas business.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-user-secret"></i>
              </div>
              <div>
                <h4 >Better customer satisfaction:</h4>
                <p className="description">
                  ISO standards are designed to enable an organisation to serve
                  their customers better and hence increase customer
                  satisfaction. ISO certification enhances customer satisfaction
                  by meeting customer requirements.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-thumbs-up"></i>
              </div>
              <div>
                <h4 >Improve product quality:</h4>
                <p className="description">
                  Since product quality matches the international level, this
                  can reduce the risk order rejections which can occur due to
                  the flaw in the product.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-tasks"></i>
              </div>
              <div>
                <h4 >Improve business efficiency:</h4>
                <p className="description">
                  ISO certification implementation enhances functional
                  efficiency of an organisation. ISO certification agency helps
                  you develop SOP's & work Instructions for all your processes.
                  ISO implementations help to manage the resources effectively,
                  as you become able to utilise all your resources to its
                  maximum extent.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-shopping-bag"></i>
              </div>
              <div>
                <h4 >Improve marketability:</h4>
                <p className="description">
                  ISO certification helps to improve the credibility of business
                  with a current & new client which leads to creating niche
                  market for business.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Featured Services Section --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0">
        <div className="container">
          <div className="section-header">
            <h2>Steps Required For ISO Registration</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    ISO Certificate Application:
                  </a>
                </h3>
                <p>
                  Apply for ISO certification on our TaxOSmart online ISO
                  application form. You need to provide complete business
                  details including the nature business, company address, years
                  of operation etc.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    ISO Audit:
                  </a>
                </h3>
                <p>
                  An ISO audit is conducted based on the documents provided. The
                  central ISO company checks for the genuineness of all the
                  documents and confirm whether the company is eligible for the
                  ISO certification or not.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    ISO Decision:
                  </a>
                </h3>
                <p>
                  Subsequently ISO certification decision is taken by ISO
                  authority based on defined procedures & an ISO certificate is
                  issued for your organisation..
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-12 col-md-6">
              <div className="card">
                <div className="card-img">
                  {/* <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    ISO Yearly Renewal:
                  </a>
                </h3>
                <p>
                  Every year a surveillance audit is conducted to ensure
                  continued adherence to your quality management system to the
                  requirements of ISO standards.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

      <section id="service" className="services pt-0">
        <div className="container">
          <div className="row gy-4 align-items-center features-item">
            <div className="section-header">
              <h2>ISO Standards</h2>
            </div>
            <div className="col-md-5 mt-0">
              <img src={PubPri} className="img-fluid" alt="" />
            </div>
            <div className="col-md-7">
              <ul>
                <li>
                  <i className="bi bi-check"></i> ISO 9001- Quality management
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 2768-1- General tolerances
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 14001- Environmental
                  management
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 27001- Information media
                  security system
                </li>
                <li>
                  <i className="bi bi-check"></i> ISO 31000- Risk management
                </li>
                <li>
                  <i className="bi bi-check"></i>ISO 4217- Currency codes
                </li>

                <li>
                  <i className="bi bi-check"></i>ISO 50001- Energy management
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Features Item --> */}

      
      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                ISO Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={IsoImg}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "ISO Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default IsoRegistration;
