import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import HomePage from "./components/HomePage";
import TdsSoftware from "./components/TdsSoftware";
import GstReconcile from "./components/GstReconcile";
import GstInvoice from "./components/GstInvoice";
import InvestmentProof from "./components/InvestmentProof";
import Einvoice from "./components/Einvoice";
import BulkForm from "./components/BulkForm";
import BulkPan from "./components/BulkPan";
import PdfSighn from "./components/PdfSighn";
import ItrNon from "./components/ItrNon";
import As26Recon from "./components/As26Recon";
import Pvt from "./components/Pvt";
import Llp from "./components/Llp";
import Opc from "./components/Opc";
import Fssai from "./components/Fssai";
import IecRegistration from "./components/IecRegistration";
import IsoRegistration from "./components/IsoRegistration";
import Dsc from "./components/Dsc";
import EsiRegistration from "./components/EsiRegistration";
import Epf from "./components/Epf";
import TradeLicence from "./components/TradeLicence";
import GstRegistration from "./components/GstRegistration";
import ProfessionalTR from "./components/ProfessionalTR";
import TradeMarkReg from "./components/TradeMarkReg";
import TrademarkAssignment from "./components/TrademarkAssignment";
import TrademarkObjection from "./components/TrademarkObjection";
import CopyRight from "./components/CopyRight";
import PatentRegistration from "./components/PatentRegistration";
import RocCompliances from "./components/RocCompliances";
import LlpCompliance from "./components/LlpCompliance";
import CompanyAnnualfiling from "./components/CompanyAnnualfiling";
import AccountingBookKpg from "./components/AccountingBookKpg";
import ChangeInDirector from "./components/ChangeInDirector";
import IncomeTaxreturn from "./components/IncomeTaxreturn";
import RevisedReturnfiling from "./components/RevisedReturnfiling";
import GstReturnFiling from "./components/GstReturnFiling";
import RespondTaxNotice from "./components/RespondTaxNotice";
import PublicLtdCompany from "./components/PublicLtdCompany";
import MSMEregistration from "./components/MSMEregistration";
import SocietyRegistration from "./components/SocietyRegistration";
import Section8Registeration from "./components/Section8Registeration";
import NidhiCRegisrtation from "./components/NidhiCRegisrtation";
import IndianSubsidiary from "./components/IndianSubsidiary";
import FooterHome from "./components/FooterPage/FooterHome";
import Blogs from "./components/Blogs/Blogs";
import Career from "./components/FooterPage/Career";
import Blog from "./components/FooterPage/Blog";
import Hsn from "./components/FooterPage/Hsn";
import ContactUs from "./components/FooterPage/ContactUs";
import AboutUs from "./components/FooterPage/AboutUs";
import TopicDetail from "./components/Blogs/TopicDetail";
import NewsArticle from "./components/Blogs/NewsArticle";
import BlogDetail from "./components/BlogDetail";
import ImportantDates from "./components/ImportantDates";
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div>
      <Router>
        <Home />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/TdsSoftware" element={<TdsSoftwareWithHelmet />} />
          <Route path="/GstReconcile" element={<GstReconcile />} />
          <Route path="/GstInvoice" element={<GstInvoice />} />
          <Route path="/InvestmentProof" element={<InvestmentProof />} />
          <Route path="/BulkForm" element={<BulkForm />} />
          <Route path="/Einvoice" element={<Einvoice />} />
          <Route path="/BulkPan" element={<BulkPan />} />
          <Route path="/PdfSighn" element={<PdfSighn />} />
          <Route path="/ItrNon" element={<ItrNon />} />
          <Route path="/As26Recon" element={<As26Recon />} />
          <Route path="/Pvt" element={<Pvt />} />
          <Route path="/Llp" element={<Llp />} />
          <Route path="/Opc" element={<Opc />} />
          {/* Start Blogs */}
          <Route path="/BlogDetail/:id" element={<BlogDetail />} />
          {/* Start Business Dropdown */}
          <Route path="/PublicLtdCompany" element={<PublicLtdCompany />} />
          <Route path="/msmeRegistration" element={<MSMEregistration />} />
          <Route path="/societyRegistration" element={<SocietyRegistration />} />
          <Route path="/Section8Registeration" element={<Section8Registeration />} />
          <Route path="/nidhicRegisrtation" element={<NidhiCRegisrtation />} />
          <Route path="/indianSubsidiary" element={<IndianSubsidiary />} />
          {/* Registration dropdown */}
          <Route path="/Fssai" element={<Fssai />} />
          <Route path="/IecRegistration" element={<IecRegistration />} />
          <Route path="/IsoRegistration" element={<IsoRegistration />} />
          <Route path="/Dsc" element={<Dsc />} />
          <Route path="/EsiRegistration" element={<EsiRegistration />} />
          <Route path="/Epf" element={<Epf />} />
          <Route path="/TradeLicence" element={<TradeLicence />} />
          <Route path="/GstRegistration" element={<GstRegistration />} />
          <Route path="/ProfessionalTR" element={<ProfessionalTR />} />
          <Route path="/TradeMarkReg" element={<TradeMarkReg />} />
          <Route path="/TrademarkAssignment" element={<TrademarkAssignment />} />
          <Route path="/TrademarkObjection" element={<TrademarkObjection />} />
          <Route path="/CopyRight" element={<CopyRight />} />
          <Route path="/PatentRegistration" element={<PatentRegistration />} />
          {/* Compliances Dropdown */}
          <Route path="/RocCompliances" element={<RocCompliances />} />
          <Route path="/LlpCompliance" element={<LlpCompliance />} />
          <Route path="/CompanyAnnualfiling" element={<CompanyAnnualfiling />} />
          <Route path="/AccountingBookKpg" element={<AccountingBookKpg />} />
          <Route path="/ChangeInDirector" element={<ChangeInDirector />} />
          {/* Tax Filing */}
          <Route path="/IncomeTaxreturn" element={<IncomeTaxreturn />} />
          <Route path="/RevisedReturnfiling" element={<RevisedReturnfiling />} />
          <Route path="/GstReturnFiling" element={<GstReturnFiling />} />
          <Route path="/RespondTaxNotice" element={<RespondTaxNotice />} />
          {/* Footer Pages Here */}
          <Route path="/Hsn" element={<Hsn />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Career" element={<Career />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/TopicDetail" element={<TopicDetail />} />
          <Route path="/NewsArticle" element={<NewsArticle />} />
          <Route path="/ImportantDates" element={<ImportantDates />} />
        </Routes>
      </Router>
      <Blogs />
      <FooterHome />
    </div>
  );
}

const TdsSoftwareWithHelmet = () => (
  <>
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta name="author" content="Taxosmart" />
      <title>TDS-O-SMART - Intelligent TDS Return Filing Software</title>
      <meta name="description" content="Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments." />
      <meta name="keywords" content="TDS-O-SMART, TDS Software, TDS Return Filing, 24Q, 26Q, 27Q, 27EQ, FVU, TDS Certificate, Form 16, Form 16A, TDS, TAX, Direct TAX, Salary return" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="TDS-O-SMART - Intelligent TDS Return Filing Software" />
      <meta property="og:description" content="Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments." />
      <meta property="og:url" content="https://www.taxosmart.com/TdsSoftware" />
      <meta property="og:site_name" content="Taxosmart" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content="https://www.example.com/tds-software" />
      <meta name="twitter:title" content="TDS-O-SMART - Intelligent TDS Return Filing Software" />
      <meta name="twitter:description" content="TDS-O-SMART is an intelligent TDS return filing software designed to ensure 100% compliance with Indian Income Tax laws. It simplifies TDS management by generating eTDS statements, correction statements, printing TDS certificates (Form 16/16A), and facilitating ePayments." />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.taxosmart.com/TdsSoftware" />
    </Helmet>
    <TdsSoftware />
  </>
);

export default App;
