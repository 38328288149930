import React, { useState } from "react";
import $ from "jquery";
import axios from "axios";
import "../assets/css/TradeMarkReg.css";
import Trademark from "../static/assetse/img/tradmark.jpg";
import Trademarkkk from "../static/img/trademarkk.jpg";

import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';

const TradeMarkReg = () => {

// Structerd Data for SEO 
const structuredData = {
  "@context": "https://schema.org",
  "@type": "Article",
  "headline": "Smart Trademark Registration",
  "author": {
    "@type": "Person",
    "name": "Taxosmart"
  },
  "datePublished": "2024-10-21",
  "image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
  "articleBody": "Learn about trademarks and how they protect your brand. Discover the importance of trademark registration in distinguishing your products from competitors, using examples like NIKE's logo and tagline Safeguard your brand identity and prevent unauthorized use.",
  "publisher": {
    "@type": "Organization",
    "name": "TAXOSMART",
    "logo": {
      "@type": "ImageObject",
      "url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
    }
  },
  "description": "Learn about trademarks and how they protect your brand. Discover the importance of trademark registration in distinguishing your products from competitors, using examples like NIKE's logo and tagline Safeguard your brand identity and prevent unauthorized use."
}


  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };
  return (
    <div>
      <Helmet>
        <title>Trademark Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="Trademark registration protects your brand or logo, allowing you to distinguish your products from competitors. Learn how to secure trademarks like NIKE's logo and tagline through TaxOSmart in Delhi NCR, Mumbai, Bengaluru, Chennai, and across India." />
        <meta name="keywords" content="Trademark Registration, brand protection, logo registration, intellectual property, TaxOSmart, Delhi NCR, Mumbai, Bengaluru, Chennai." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container tradTop">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="fs-1">Trademark Registration</h1>
              <p>File Trademark to Protect your Brand Name / Logo</p>
              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start" id="resp">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Get In Touch</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={Trademark} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- End Call To Action Section --> */}
      <br />
      <section id="service" className="services pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    'TM' Symbol
                  </a>
                </h3>
                <p>
                  This symbol can use with the brand name until your trademark
                  is not registered and the application is in pending status.
                  This is to inform the public of his exclusive claim over the
                  brand. The claim may or may not be valid depending upon the
                  result of trademark registration.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    'SM' Symbol
                  </a>
                </h3>
                <p>
                  It is also a kind of trademark which Service Mark, but it
                  identifies and distinguishes the source of a service rather
                  than a product. For example, you will use TM for a product and
                  SM for a service like housekeeping.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    {" "}
                    'R' Symbol
                  </a>
                </h3>
                <p>
                  This symbol is only applicable for your brand when the mark is
                  being registered and the Certificate of Registration is issued
                  by the Trademark Registrar. Among all the words which are
                  being mentioned above, TM is the most common word which is
                  being used frequently by owners of the brand.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>

      {/* <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about pt-0 pb-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={Trademark} className="img-fluid imgTop" alt=""  style={{marginTop:'-6%'}}/>
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <h3>Trademark Registration</h3>
              <p>
                Basically, a trademark is a "brand" or "logo" that you can use
                to distinguish your product from those of your competitors.
                Through trademark registration, you can protect your brand or
                logo by restricting other people from using the same. For e.g.
                the logo of NIKE and their tagline JUST DO IT is a registered
                trademark. It provides them protection as it is illegal to use
                the same logo or tagline.
              </p>
              <p>
                Trademark Registration can be done through TaxOSmart in Delhi
                NCR, Mumbai, Bengaluru, Chennai & all other Indian cities.
              </p>
              <ul>
                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bi bi-diagram-3"></i>
                  <div>
                    <h5 className="fs-3">What Is Included In Our Package?</h5>
                    <p>
                      Consultation , Application Preparation, Name Search and
                      Approval, Application Filling, Same day Filling,
                      Government fees
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first" >
              <img src={Trademarkkk} className="img-fluid imgTop" alt="" style={{marginTop:'-6%'}} />
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <h2 className="fs-1">About Trademark Registration</h2>
              <p>
                A registered trademark is an intangible asset for a business and
                is used to protect the company's investment in the brand or
                symbol. TM number is assigned within a period of three days by
                Trademark department but it takes almost two years for it to be
                registered so that you can use the symbol with your brand name.
                It is always advised to get trademark registration because
                getting your company registered will not protect your brand
                against those who might initiate using identical or similar
                marks.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0">
        <div className="container">
          <div className="section-header">
            <h2>Advantages Of Trademark Registration</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/storage-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Legal Protection
                  </a>
                </h3>
                <p>
                  You become the legal owner of the registered trademark and no
                  another person has the right to use your registered trademark
                  without your prior permission. No action can be taken against
                  a 2nd party if the trademark is not registered with the
                  government. The registered trademark holder can take some
                  legal action against anyone who tries to copy the trademark
                  with any prior permission
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/logistics-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Unique Identity
                  </a>
                </h3>
                <p>
                  One of the best qualities of trademark registration is that it
                  helps you to establish a unique identity of your company. And
                  no other competitor can apply or use your trademark for
                  similar goods or services.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/cargo-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Creation of Asset
                  </a>
                </h3>
                <p>
                  Registered trademark can act as an asset as the owner of the
                  trademark can easily sell, franchise or allow it on the
                  contract basis to another party. It creates a kind of
                  intellectual property for the same.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/trucking-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Trust or Goodwill
                  </a>
                </h3>
                <p>
                  As registered trademark brings the uniqueness to your product,
                  it can easily create a sense of trust, goodwill and quality in
                  the minds of your customer.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/packaging-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Popularise your brand
                  </a>
                </h3>
                <p>
                  Registered trademark is easily searchable as it is available
                  in government trademark database. It also helps you to get
                  popular among people which are a great sign for your company.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6">
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/img/warehousing-service.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Access to tenders:
                  </a>
                </h3>
                <p>
                  There are multiple government tenders which are open only to
                  MSMEs to promote small business participation in the India.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}

     
      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                {/* <!-- <img src="./././static/img/success.png" height="20" width="20"> --> */}
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Trad Mark Registeration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Trademark}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Trademark Registeration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeMarkReg;
