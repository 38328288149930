import axios from "axios";
import React, { useState } from "react";
import $ from "jquery";
import "../assets/css/MSMEregiteration.css";
import MSMEpng from "../static/img/msme.png";
import MSMEjpg from "../static/assetse/img/msme.jpg";
import MSMEjpeg from "../static/image/img/msme.jpeg";

import SucWeb from "../static/img/Succ.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

const MSMEregistration = () => {
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "MSME/SSI Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover the significance of MSME (Micro, Small and Medium Enterprises) registration and its benefits. Learn how MSME registration under the MSMED Act can unlock government subsidies and support, driving economic growth and equitable development for all businesses.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover the significance of MSME (Micro, Small and Medium Enterprises) registration and its benefits. Learn how MSME registration under the MSMED Act can unlock government subsidies and support, driving economic growth and equitable development for all businesses."
	}
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", formData)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <Helmet>
        <title>MSME/SSI Registration</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="MSME (Micro, Small and Medium Enterprises) registration enables businesses to access government subsidies and benefits under the MSMED Act, driving economic growth and promoting equitable development." />
        <meta name="keywords" content="MSME registration, SSI registration, Micro Small Medium Enterprises, MSMED Act, government subsidies, economic growth, business benefits." />
        <meta name="author" content="Taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <main>
        {/* <!-- ======= Hero Section ======= --> */}
        <section id="hero" className="hero d-flex align-items-center">
          <div className="container">
            <div className="Topbox">
              <div className="topcontents">
                <h1>MSME/SSI Registration</h1>
                <div data-aos="" data-aos-delay="600">
                  <div className="text-center text-lg-start">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#demoModal"
                      href=""
                      data-bs-backdrop="false"
                      className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    >
                      <span>Get Started</span>{" "}
                      <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="topimg">
                <img src={MSMEpng} className="img-fluid itrImage" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Hero --> */}

        <br />
        {/* <!-- ======= Featured Services Section ======= --> */}

        <section id="featured-services" className="featured-services">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="fa fa-solid fa fa-truck"></i>
                </div>
                <div>
                  <h4 className="">Micro Enterprises</h4>
                  <p className="description">
                    Micro-enterprises are the smallest entities, of course. The
                    investment under Micro manufacturing enterprises should be
                    less than Rs. 25 lakhs in plant and machinery, whereas, the
                    Micro Service Enterprises should not exceed Rs. 10 lakhs
                    investment.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="fa fa-newspaper"></i>
                </div>
                <div>
                  <h4 className="">Small Enterprises</h4>
                  <p className="description">
                    In Small Manufacturing Enterprises, the investment should be
                    in between Rs. 25 lakh and Rs. 5 crores in plant and
                    machinery, while in Small Service Enterprises this
                    investment limits between Rs. 10 lakh and Rs. 2 crores.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}

              <div className="col-lg-4 col-md-6 service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className=" fa fa-globe"></i>
                </div>
                <div>
                  <h4 className="">Medium Enterprises</h4>
                  <p className="description">
                    And if we'll talk about the Medium manufacturing
                    enterprises, then the investment should be in between Rs. 5
                    crore and Rs. 10 crores in plant and machinery, and for
                    Small Service Enterprises it should be in between Rs. 2
                    crore and Rs. 5 crores.
                  </p>
                </div>
              </div>
              {/* <!-- End Service Item --> */}
            </div>
          </div>
        </section>
        <br />
        <br />
        {/* <!-- End Featured Services Section --> */}

        {/* <!-- ======= About Us Section ======= --> */}
        <section id="about" className="about pt-0">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img src={MSMEjpg} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>MSME/SSI Registration</h3>
                <p>
                  MSME stands for Micro, Small and Medium Enterprise. MSME or
                  SSI enterprises are the foundation of any economy and are an
                  engine of economic growth, advancing impartial improvement for
                  all. MSME registration or SSI registration helps businesses
                  avail multiple government subsidies and benefits MSMED act.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-diagram-3"></i>
                    <div>
                      <h5 className="fs-3">What Is Included In Our Package?</h5>
                      <p>
                        Government Registration Fees , Application Preparation,
                        Obtain MSME Certificate
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End About Us Section --> */}

        <br />

        {/* <!-- ======= Services Section ======= --> */}
        <section id="service" className="services pt-0 mt-0">
          <div className="container">
            <div className="section-header mt-0 pt-0">
              <h2>ADVANTAGES OF MSME / SSI REGISTRATION</h2>
            </div>

            <div className="row gy-4">
              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/storage-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Cheaper bank loans:
                    </a>
                  </h3>
                  <p>
                    The rate of interest on loan offered to MSMEs is 1-1.5%
                    lower compared to typical business loan interest.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/logistics-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Easy access to credit:
                    </a>
                  </h3>
                  <p>
                    PM Modi has introduced the Mudhra Loan scheme, which
                    provides loans to MSME / SSI without collaterals.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/cargo-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Quicker approvals from state and central government
                      bodies:
                    </a>
                  </h3>
                  <p>
                    Business registered under MSME are given higher preference
                    in terms of government license and certification.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/trucking-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Tax rebates:
                    </a>
                  </h3>
                  <p>
                    MSME / SSI registered businesses enjoy multiple income taxes
                    and capital gains tax subsidies from the government.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/packaging-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Cheaper infrastructure:
                    </a>
                  </h3>
                  <p>
                    Charges are lower for MSME registered company for facilities
                    such as electricity and VAT exemptions. In fact, other
                    business services such as patents are also cheaper for MSMEs
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}

              <div className="col-lg-4 col-md-6">
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/warehousing-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="service-details.html" className="stretched-link">
                      Access to tenders:
                    </a>
                  </h3>
                  <p>
                    There are multiple government tenders which are open only to
                    MSMEs to promote small business participation in the India.
                  </p>
                </div>
              </div>
              {/* <!-- End Card Item --> */}
            </div>
          </div>
        </section>
        {/* <!-- End Services Section --> */}

       
      </main>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                MSME/SSI Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={MSMEpng}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "MSME Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>

            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div>
                <div className="col-sm-3">
                  <img src={SucWeb} alt="" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success fs-18"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MSMEregistration;
