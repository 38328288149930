import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import "../assets/css/IecRegistration.css";
import Iec from "../static/image/img/iec.jpg";
import ExImp from "../static/assetse/img/importExport.jpg";
import Imp from "../static/assetse/img/import.jpg";
import PubPri from "../static/assetse/img/publicPrivate.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";


const IecRegistration = () => {


  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "IEC Registration",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/assets/img/minslider/GST.png", 
		"articleBody": "Discover the Importer Exporter Code (IEC), a 10-digit number issued by DGFT essential for all import-export transactions. Obtain your IEC in either your personal name or company name to streamline your international trade operations.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Discover the Importer Exporter Code (IEC), a 10-digit number issued by DGFT essential for all import-export transactions. Obtain your IEC in either your personal name or company name to streamline your international trade operations."
	}

  const [modal, setModal] = useState(false);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [IsVisible, setIsVisible] = useState(true);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {
      setError("Captcha is requred.");
      return;
    }

    const dataToSubmit = {
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true);
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (
    <div>
      <Helmet>
        <title>IMPORT EXPORT CODE (IEC)</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="The Import Export Code (IEC) is a 10-digit code provided by DGFT, essential for all import and export transactions. Valid for a lifetime, it can be obtained in your personal or company name, covering all divisions and branches." />
        <meta name="keywords" content="Import Export Code, IEC, importer exporter code, import export license, DGFT, lifetime validity, IE code." />
        <meta name="author" content="taxosmart" />
        <meta name="robots" content="noindex" />

      </Helmet>
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="Topbox">
            <div className="topcontent">
              <h1 className="fs-1">IEC Registration</h1>
              <p>Ideal for Businesses Planning to Trade In and Out of India</p>
              {/* <div data-aos="" data-aos-delay=600 */}
              <div data-aos="" data-aos-delay="600">
                <div className="text-left" id="resp">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="text-white">Get In Touch</span>
                    <i className="bi bi-arrow-right text-white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg">
              <img src={Iec} className="img-fluid iecImgTop" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div
              id="aboutId"
              className="col-lg-6 content order-last  order-lg-first"  style={{marginTop:'10%'}}
            >
              {/* <!-- <h3>What Is IMPORT EXPORT CODE?</h3> --> */}
              <div className="section-header pt-0">
                <h2>What Is IMPORT EXPORT CODE?</h2>
              </div>
              <p>
                IEC is known as many names such as importer exporter code,
                import export code, import export license, import export number,
                IE code, IEC license, IE license etc. In simple terms, IEC is a
                10 digit code which is provided by DGFT required for import
                export transactions. You can take IE code in your personal name
                or company name.
              </p>
              <p>
                An IEC number issued to applicant shall be valid for all its
                divisions/ units/ factories/ branches. IEC is valid for lifetime
                and there is no need to renew the same.
              </p>
            </div>

            <div
              id="ImageImport"
              className="col-lg-6 position-relative align-self-start order-lg-last order-first"
            >
              <img src={ExImp} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      {/* // <!-- End About Us Section --> */}

      {/* // <!-- ======= About Us Section ======= --> */}
      <section id="about" className="about pt-0">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
              <img src={Imp} className="ImpImg img-fluid" alt=""  style={{marginTop:'-9%'}}/>
            </div>
            <div className="col-lg-6 content order-last  order-lg-first">
              <p>
                IEC registration is required by a person for exporting or
                importing goods. It is a 10 digit code which is issued by the
                Directorate General of Foreign Trade (DGFT). All businesses
                which are engaged in Import and Export of goods require
                registering Import Export Code. IE code has lifetime validity.
                Importers are not allowed to proceed without this code and
                exporters can't take benefit of exports from DGFT, customs,
                Export Promotion Council, if they don't have this code.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Us Section --> */}

      {/* <!-- ======= Features Section ======= --> */}
      <section id="features" className="features whatTop">
        <div className="container">
          <div className="section-header pt-0">
            <h2>What Is Included In Our Import Export Code Package?</h2>
          </div>

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4">
                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Drafting Document</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Application Filling</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Application Processing</h3>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Completion of registration</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- / row --> */}
        </div>
      </section>
      {/* <!-- End Features Section --> */}

      <br></br>
      {/* <!-- ======= Featured Services Section ======= --> */}
      <section id="featured-services" className="featured-services whatTopApp">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-solid fa fa-truck"></i>
              </div>
              <div>
                <h4 >Application Preparation:</h4>
                <p className="description">
                  An application must be made in the Aayaat Niryaat Form 2A
                  format to the DGFT in that region where the registered office
                  of the company is established. Government has also arranged
                  online filing facility for obtaining IEC.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div
              className="col-lg-6 col-md-6 service-item d-flex"
              data-aos-delay="100"
            >
              <div className="icon flex-shrink-0">
                <i className="fa fa-desktop" aria-hidden="true"></i>
              </div>
              <div>
                <h4 >Application Processing:</h4>
                <p className="description">
                  Details of the business and its proprietors, directors or
                  partners, bank account details and a bank certificate are the
                  basic requirements of ANF 2A. All the required documents for
                  IEC need to be submitted with ANF 2A. Digital signature is
                  required on the ANF2A form
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-money" aria-hidden="true"></i>
              </div>
              <div>
                <h4 >IEC fee payment:</h4>
                <p className="description">
                  Once the application is completed, it should be filed with
                  DGFT and an application fee need to be paid to the government.
                  IEC department may send back your application for correction /
                  change. You need to reply back to the DGFT in 15 days.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}

            <div className="col-lg-6 col-md-6 service-item d-flex">
              <div className="icon flex-shrink-0">
                <i className="fa fa-university" aria-hidden="true"></i>
              </div>
              <div>
                <h4 >IE Code Issuance:</h4>
                <p className="description">
                  Once the application is completely verified by the department,
                  the Directorate General of Foreign Trade will allot an IE Code
                  for your business. This IE code comes in both soft copy and
                  hard copy. You receive the softcopy via email and can start
                  the import export transaction with this.
                </p>
              </div>
            </div>
            {/* <!-- End Service Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Featured Services Section --> */}

      {/* <!-- ======= Services Section ======= --> */}
      <section id="service" className="services pt-0">
        <div className="container">
          <div className="section-header pt-0">
            <h2>Advantages Of Import Export Code</h2>
          </div>

          <div className="row gy-4">
            <div className="col-lg-4 col-md-6" data-aos-delay="100">
              <div className="card">
                <div className="card-img">
                  {/* <img src={ServiceStorage} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Business growth:
                  </a>
                </h3>
                <p>
                  Taking your services or products to the international market
                  will enlarge your businesses. IEC enable you to sell products
                  outside India.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6" data-aos-delay="200">
              <div className="card">
                <div className="card-img">
                  {/* <img src={Lg} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Export subsidize:
                  </a>
                </h3>
                <p>
                  On the basis of IEC, companies or firms can benefits and
                  subsidize their exports/ imports from DGFT, Customs and export
                  promotion council.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6" data-aos-delay="300">
              <div className="card">
                <div className="card-img">
                  {/* <img src={Cs} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Lifetime validity:
                  </a>
                </h3>
                <p>
                  IE Code is issued for the lifetime of the entity and requires
                  no renewal. So once an IE Code is obtained, it can be used by
                  that entity for all its import or export transactions without
                  any further hassles.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6" data-aos-delay="300">
              <div className="card">
                <div className="card-img">
                  {/* <img src={Cs} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    No Return Filing:
                  </a>
                </h3>
                <p>
                  IE Code does not require filing of any return. Once, an IE
                  Code is issued there are no further procedures required to
                  maintain validity of the IE Code. Even if import or export
                  transactions occur, there are no filings required to DGFT
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}

            <div className="col-lg-4 col-md-6" data-aos-delay="300">
              <div className="card">
                <div className="card-img">
                  {/* <img src={Cs} alt="" className="img-fluid" /> */}
                </div>
                <h3>
                  <a href="service-details.html" className="stretched-link">
                    Individuals can have IE Code:
                  </a>
                </h3>
                <p>
                  Even individuals who are proprietors of a business can obtain
                  IE Code in their name. It is not necessary to incorporate a
                  business entity for obtaining IE Code.
                </p>
              </div>
            </div>
            {/* <!-- End Card Item --> */}
          </div>
        </div>
      </section>
      {/* <!-- End Services Section --> */}
      <section id="service" className="services pt-0">
        <div className="container">
          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-5">
              <img src={PubPri} className="img-fluid" alt="" />
            </div>

            <div className="col-md-7">
              <h3 className="mt-0">
                Documents Required for IEC in respect of individual/ Proprietor/
                Partner/ Director
              </h3>

              <ul>
                <li>
                  <i className="bi bi-check"></i> Copy of PAN Card
                </li>
                <li>
                  <i className="bi bi-check"></i> Passport size photograph
                </li>
                <li>
                  <i className="bi bi-check"></i> Telephone Bill in the name of
                  the Individual (In case of individual)
                </li>
                <li>
                  <i className="bi bi-check"></i> Authorization letter (In case
                  of Partnership)
                </li>
                <li>
                  <i className="bi bi-check"></i> Authority from other Directors
                  on letter head (In case of company)
                </li>
                <li>
                  <i className="bi bi-check"></i> Copy of Partnership deed (In
                  case of Partnership)
                </li>
                <li>
                  <i className="bi bi-check"></i>Cancelled cheque of individual
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- Features Item --> */}
        </div>
      </section>

      <section id="service" className="services pt-0">
        <div className="container">
          <div className="row gy-4 align-items-center features-item">
            <div className="col-md-7">
              <h3>Documents Required for IEC in respect of Entity</h3>
              <ul>
                <li>
                  <i className="bi bi-check"></i> Copy of PAN Card firm/company
                </li>
                <li>
                  <i className="bi bi-check"></i> Copy of Property papers (If
                  owned property)
                </li>
                <li>
                  <i className="bi bi-check"></i> Copy of Rent agreement (If
                  rented property)
                </li>
                <li>
                  <i className="bi bi-check"></i> Landlord NOC (Format will be
                  provided by us)
                </li>
                <li>
                  <i className="bi bi-check"></i> Cancelled cheque of firm
                </li>
                <li>
                  <i className="bi bi-check"></i> Electricity/ water bill
                  (Business Place)
                </li>
                <li>
                  <i className="bi bi-check"></i>Telephone Bill in the name of
                  the business
                </li>
                <li>
                  <i className="bi bi-check"></i>Copy of Service Tax returns (In
                  case of Proprietorship)
                </li>
              </ul>
            </div>
            <div className="col-md-5">
              <img src={PubPri} className="img-fluid resps" alt="" />
            </div>
          </div>
          {/* <!-- Features Item --> */}
        </div>
      </section>

      <section id="service" className="services pt-0">
        <div className="container">
          <div className="row container align-items-center features-item">
            <div className="feture-code col-md-12">
              <h3 className="pl-2">Features Of Import Export Code</h3>
              <ul>
                <li>
                  <i className="bi bi-check"></i> All importers who import goods
                  are required to register Import export code and all exporters
                  need this code to obtain the benefits of exports.
                </li>
                <li>
                  <i className="bi bi-check"></i> IEC code holder does not
                  require filing of any returns.
                </li>
                <li>
                  <i className="bi bi-check"></i> IEC Code is issued for
                  lifetime and requires no renewal.
                </li>
                <li>
                  <i className="bi bi-check"></i>Even individuals who are
                  proprietors can obtain the code in their name. It is not
                  necessary to setup a business for the same.
                </li>
              </ul>
            </div>
          </div>
          {/* <!-- Features Item --> */}
        </div>
      </section>

      {/* </main> */}
      {/* <!-- End #main --> */}

      {/* </body> */}

      {/* Success Modal */}
      <div>
        {IsVisible && (
          <Modal show={successModalVisible} onHide={closePop}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {IsVisible && (
          <Modal show={errorModalVisible} onHide={handleCloseError}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                IEC Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Iec}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "IEC Registration")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button
                      type="submit"
                      className="btn btn-success"
                      disabled={!captchaToken}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="successMsg" className="modal fade" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-header">
              <h4>
                {/* <!-- <img src="./././static/img/success.png" height="20" width="20"> --> */}
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                &nbsp;&nbsp;<span className="headingMsg"></span>
              </h4>
            </div>
            <div className="modal-body">
              <div className="d-flex">
                <div className="col-sm-3">
                  {/* <img src={Suc} height="120" /> */}
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="col-sm-9">
                  <div className="msg"></div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick="window.location.reload()"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="errorMsg" className="modal" role="dialog">
        <div className="modal-dialog modal-lg">
          {/* <!-- Modal content--> */}
          <div className="modal-content">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Entered Data Is Incorrect</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick="window.location.reload()"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick="window.location.reload()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Import Export Registration
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Iec}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "TDS")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IecRegistration;
