import axios from "axios";
import React, { useState } from "react";
import "../assets/css/bulkForm.css";
import bulkFormImage from "../static/img/bulkformLogo.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';

const BulkForm = () => {

  
  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "Auto Prepare and upload Bulk Form 15CA and 15CB",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/img/i-smart.png", 
		"articleBody": "Form15CA is a Declaration given by Remitter and is used as a tool by statutory authorities for collecting information in respect of payments which are chargeable to tax in the hands of recipient non-resident.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/public/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "Form15CB is the Tax Determination Certificate where the issuer CA examines the remittance having regard to chargeability provisionsu/s 5 and 9 of Income tax Act along with provisions of Double tax Avoidance Agreements with the Recipient's Residence Country.",
	  "keyword" : "T Bill, Billing Software, GST software, GST App, GST solution, GST website, GST invoice"
  }

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };

    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null);
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
      <html>
    <body>
    <head>
      <Helmet>
        <title>Form 15CA & 15CB Compliance</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="description" content="A person responsible for making a payment to a non-resident or to a foreign company has to comply with form 15CA & 15CB provisions." />
        <meta name="keywords" content="Bulk form 15CA & 15CB, 15CA / 15CB bulk e-filing solution, auto bulk form filling uploading/ downloading" />
        <meta name="author" content="Taxosmart" />


        {/* <!-- Open Graph (for Facebook) --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Form 15CA & 15CB Compliance" />
        <meta property="og:description" content="A person responsible for making a payment to a non-resident or foreign company must comply with form 15CA & 15CB provisions." />
        <meta property="og:url" content="https://www.taxosmart.com/ca15cb15" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter Card --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Form 15CA & 15CB Compliance" />
        <meta name="twitter:description" content="A person responsible for making a payment to a non-resident or foreign company must comply with form 15CA & 15CB provisions." />
        <meta name="twitter:url" content="https://www.taxosmart.com/ca15cb15" />

        {/* <!-- Canonical Link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/ca15cb15" />

        {/* <!-- Bing and other robots --> */}
        <meta name="robots" content="index, follow" />
      </Helmet>
      </head>
      <section id="hero" className="hero d-flex align-items-center heroTop">
        <div className="container topClass animated slideInDown">
          <div className="Topbox">
            <div className="topcontentS">
              <h1 className="animated slideInDown auto15CACB fs-2">
                Auto Prepare and upload Bulk Form 15CA and 15CB
              </h1>
              <div data-aos="" data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center "
                    id="padd"
                  >
                    <span>Get Started</span>{" "}
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={bulkFormImage}
                className="img-fluid bulkFormStyle"
                alt=""
                style={{ width: '60%', height: 'auto' }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mtop">
        <div className="container-fluied" data-aos="">
          <div className="row gx-0">
            <div
              className="col-lg-12 d-flex flex-column justify-content-center"
              data-aos=""
              data-aos-delay="200"
            >
              <div className="container content">
                <h1 className="  slideInDown introDuction">
                  Introduction to Form 15CA/15CB
                </h1>
                <br />
                <p className="IntroductionTop personTop text-justify">
                  A Person Responsible For Making a Payment to a non-resident or
                  to a foreign company has to comply with form 15CA & 15CB
                  provisions
                </p>
                <p className="text-justify">
                  Form15CA is a Declaration given by Remitter and is used as a
                  tool by statutory authorities for collecting information in
                  respect of payments which are chargeable to tax in the hands
                  of recipient non-resident.
                </p>
                <p className="text-justify">
                  Form15CB - The person making the payment needs to obtain a
                  certificate from a Chartered Accountant in Form 15CB.
                </p>
                <p className="text-justify">
                  Form15CB is the Tax Determination Certificate where the issuer
                  CA examines the remittance having regard to chargeability
                  provisionsu/s 5 and 9 of Income tax Act along with provisions
                  of Double tax Avoidance Agreements with the Recipient's
                  Residence Country.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluied aos-init aos-animate" data-aos="">
        <div className="row gx-0">
          <div
            className="col-lg-12 d-flex flex-column justify-content-center aos-init aos-animate"
            data-aos=""
            data-aos-delay="200"
          >
            <div className="container content">
              <h1
                className="   slideInDown"
                style={{
                  font: "31px",
                  color: "#0f316d",
                  textAlign: "center",
                  marginTop: "6%",
                }}
              >
                New rules effective from 1st April 2016
              </h1>
              <br />
              <p className="incomeTop">
                The income tax department has revised the rules relating to
                preparation &amp; submission of Form 15CA and Form 15CB. The
                important changes are as below:-
              </p>
              <p>
                Form 15CA and 15CB shall be not be required to be furnished by
                an individual for remittance, which does not require RBI
                approval.
              </p>
              <p>
                List of payments of specified nature mentioned in Rule 37BB,
                which do not require submission of Forms 15CA and 15CB, has been
                expanded from 28 to 33 including payments for imports.
              </p>
              <p>
                Form No. 15CB will only be required for payments made to
                non-residents, which are taxable and if the payment exceeds Rs.
                5 lakhs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section id="features" className="features taxosmartTop topCssDiv">
        <div className="container" data-aos="">
          <h1 className="   slideInDown taxosmartCss">
            TAXOSMART'S auto bulk 15CA/CB Process
          </h1>
          <br />
          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4 decTop">
                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Auto Bulk Form Prepration.</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Auto Bulk Uploading on income tax site.</h3>
                  </div>
                </div>

                <div
                  className="col-md-6"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Auto Downloading of acknowledgement</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features featuresTop">
        <div className="container aos-init aos-animate" data-aos="">
          <h1
            className="  slideInDown"
            style={{ font: "31px", color: "#0f316d", textAlign: "center" }}
          >
            Features
          </h1>
          <br />

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4 decTop">
                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="700"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Ease of use.simple navigation.excel based with ready to
                      use template
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Supports Unlimited line items/forms.</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Auto uploading the forms on income tax website.</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      Auto Downloading of acknowledgement / forms from income
                      tax website
                    </h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="600"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      All information/ details will be filled automatically
                      enabling auto prepration of forms
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="features">
        <div className="container aos-init aos-animate" data-aos="">
          <h1
            className="  slideInDown"
            style={{ color: "#0f316d", textAlign: "center" }}
          >
            Advantages
          </h1>
          <br />

          <div className="row">
            <div className="col-lg-12 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4 decTop">
                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Hassle free and faster Output.</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="300"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Accurate without any Error.</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="400"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>Huge Cost saving</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="500"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>No manually work/ fully Automated</h3>
                  </div>
                </div>

                <div
                  className="col-md-6 aos-init aos-animate"
                  data-aos="zoom-out"
                  data-aos-delay="600"
                >
                  <div className="feature-box d-flex align-items-center">
                    <i className="bi bi-check"></i>
                    <h3>
                      End-to-End Support- auto bulk form filling uploading/
                      Downloading
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                Form 15CA and 15CB
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={bulkFormImage}
                  style={{ width: "70%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "Form 15CA and 15CB")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Helmet>
  <script type="application/ld+json">
    {JSON.stringify(structuredData)}
  </script>
</Helmet>
</body>
</html>
    </div>
  );
};
export default BulkForm;
