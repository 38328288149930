import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "../assets/css/topicDetail.css";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";

const TopicDetail = () => {
  const { id } = useParams();

  const [topicData, setTopicData] = useState({});
  const [imageData, setImageData] = useState({});
  const [topicContent, setTopicContent] = useState({});

  const getDetail = async () => {
    try {
      const response = await axios.get(
        `/apiblogs/detail/${id}`
      );
      setTopicData(response.data.topic);
      setImageData(response.data.image);
      setTopicContent(response.data.topicContent);
      // console.log(response.data.topic);
      // console.log(response.data.image);
      // console.log(response.data.topicContent);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sanitizedContent = DOMPurify.sanitize(topicContent.content || "");

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <br />
      <br />
      <br />
      <main class="container">
        <div class="row">
          <h2>{topicData.heading || "No Title"}</h2>
          <p class="text-muted">Posted on {topicData.date || "No Date"}</p>
          <article class="col-md-8 blog-post card">
            <img
              src={`data:image/png;base64,${imageData.image}`}
              class="img-fluid"
              alt={imageData.altText || "Blog Image"}
              style={{ marginTop: "0%" }}
            />
            <br></br>{" "}
            <p style={{ fontWeight: "bold", fontSize: "22px" }}>
              {topicData.heading || "No Title"}
            </p>
            <h5
              className="card-text"
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            ></h5>
          </article>
          <aside class="col-md-4">
            {/* <div class="p-3 mb-3 bg-light rounded">
                    <h4>Categories</h4>
                    <ul class="list-unstyled">
                        <li><a href="#">1. Others</a></li>
                        <li><a href="#">2. Indirect Tax</a></li>
                        <li><a href="#">3. Direct Tax</a></li>
                        <li><a href="#">4. Compliance Due Date Chart</a></li>
                        <li><a href="#">5. Company Low</a></li>
                        <li><a href="#">6. Audit</a></li>
                    </ul>
                </div> */}

            {/* <div class="p-3 mb-3 bg-light rounded">
                    <h4>Archives</h4>
                    <ul class="list-unstyled">
                        <li><a href="#">September 2024</a></li>
                        <li><a href="#">August 2024</a></li>
                        <li><a href="#">July 2024</a></li>
                    </ul>
                </div> */}
          </aside>
        </div>
      </main>
    </div>
  );
};

export default TopicDetail;
