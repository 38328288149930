import React, { useState } from "react";
import axios from "axios";

import "../../assets/css/Hsn.css";
import tdsPageImage from "../../static/img/tds img.jpg";
const Hsn = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/apiCustomerDetail/", formData)
      .then((response) => {
        setSuccess("Registration successful!");
        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
      })
      .catch((error) => {
        setError("Error during registration: " + error.message);
        setSuccess("");
      });
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <h1 className="text-center">HSN/SAC Code List & GST Rate Finder</h1>
          <br />
          <form className="form-inline mobile">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                ng-model="hctr.search.heading"
                placeholder="hsn/sac code list & gst rate finder"
              />
              <button
                className="btn btn-primary"
                ng-click="hctr.searchHsn(true,'hsn')"
              >
                Search
              </button>
            </div>
          </form>

          <form className="form-inline mn_menu SearchBar">
            <div className="form-group form1">
              <input
                type="text"
                className="form-control"
                ng-model="hctr.search.heading"
                placeholder="hsn/sac code list & gst rate finder"
              />
              <button
                className="btn btn-primary"
                ng-click="hctr.searchHsn(true,'hsn')"
              >
                Search
              </button>
            </div>
          </form>
        </div>
        <div className="row" ng-hide="hctr.getHsnListData().length==0">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr style={{ backgroundColor: "#32375e", color: "white" }}>
                  <th>Sr.No</th>
                  <th>Heading</th>
                  <th>Description</th>
                  <th>CGST</th>
                  <th>SGST</th>
                  <th>IGST</th>
                  <th>CESS</th>
                </tr>
              </thead>
              {/* <tbody>
						<tr ng-repeat="a in hctr.getHsnListData() | orderBy: heading: true">
							<td>{{$index+1}}</td>
							<td>{{a.heading}}</td>
							<td>{{a.description}}</td>
							<td>{{a.cgst}}</td>
							<td>{{a.sgst}}</td>
							<td>{{a.igst}}</td>
							<td>{{a.cess}}</td>
						</tr>
					</tbody> */}
            </table>
          </div>
        </div>
        <br />
      </div>
      <br />
      <br />
      <div
        style={{
          paddingBottom: "40px",
          paddingTop: "40px",
          backgroundColor: "#e1e3e1",
        }}
      >
        <div className="row">
          <div className="hsn">
            <h2>What is HSN Code?</h2>
            <p>
              HSN stands for Harmonized System of Nomenclature. HSN was raised
              and industrialized by the WCO (World Customs Organization) in the
              year 1988. Since then 5 revisions has already been implemented
              worldwide. HSN code asserts more than 5000 goods meticulously and
              is widely used by more than 150 countries. It is a globally
              accepted multipurpose tool for customs and trade procedure.
              Currently, more than 95% of trade is under the WCO and every
              country is following 2017 revised HSN codes including India.
            </p>
            <div className="extraDiv"></div>
            <h2>HSN Codes for GST in India:</h2>
            <p className="hsnCodes">
              HSN will be applicable in India after implementation of GST. India
              has already been using HSN system in the Central Excise and
              Customs regime.
            </p>
            <p className="hsnCodes">
              It is a much more detailed classNameification that added another
              two digits to the 6-digit structure. There is a requirement to
              mention HSN code of items in tax invoice under GST.
            </p>
            <p className="hsnCodes">
              Provisions related to HSN under GST are given below:-
            </p>
            <ul>
              <p className="hsnCodes">
                <i className="fa fa-check-square" aria-hidden="true"></i>
                &nbsp;&nbsp;Now the Govt. has given some relief to small
                taxpayer with annual turnover up to Rs 1.50 crore in preceding
                financial year need not be required to mention HSN code in their
                tax invoice.
              </p>
              <p className="hsnCodes">
                <i className="fa fa-check-square" aria-hidden="true"></i>
                &nbsp;&nbsp;Taxpayers having turnover exceeds Rs. 1.5 crore but
                up to 5.00 crore will be required to mention only two digits of
                HSN code.
              </p>
              <p className="hsnCodes">
                <i className="fa fa-check-square" aria-hidden="true"></i>
                &nbsp;&nbsp;Taxpayers having turnover exceeds Rs 5.00 crore will
                be required to mention four digits of HSN code.
              </p>
            </ul>

            <div className="extraDiv"></div>
            <h2>How do HSN work?</h2>
            <p className="hsnCodes">
              HSN is a six-digit code that classNameifies more than 5000
              products, arranged in a legal and logical structure. To achieve
              uniform classNameification, the HSN is supported by well-defined
              rules and is accepted worldwide
            </p>
            <p className="hsnCodes">
              HSN classNameification is widely used for taxation purposes by
              helping to identify the rate of tax applicable to a specific
              product in a country that is under review. It can also be used in
              calculations that involve claiming benefits. Yet that's not all-
              it also applies to import and exports. The HSN code aids in
              determining the quantity of all items imported or traded through a
              nation.
            </p>

            <div className="extraDiv"></div>
            <h2>The Importance of the HSN Code</h2>
            <p className="hsnCodes">
              While the primary purpose of the HSN code is the systematic
              classNameification of goods, it can also be used to gather data
              and solve problems that would otherwise be difficult to obtain.
              The result is a more efficient international trade system. HSN is
              in use worldwide, with 200+ countries participating. This
              impressive adoption rate can be chalked up to the benefits of HSN,
              which include:
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Collection of international trade statistics
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Provision of a rational basis for Customs tariffs
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Uniform classNameification
            </p>
            <p className="hsnCodes">
              Nearly 98% of international trade stock is classNameified in terms
              of HSN, further evidence of its reputation as the best form of
              international classNameification.
            </p>
            <p className="hsnCodes">
              HSN numbers exist for each commodity in every country, and the
              number remains the same for almost all goods. In some countries,
              HSN numbers can vary a little because they are entirely based on
              the nature of the items classNameified.
            </p>
            <div className="extraDiv"></div>
            <h2> CASE STUDY</h2>
            <p className="hsnCodes">
              India has been a member of the WCO (World Customs Organization)
              since 1971. It initially employed six-digit HSN codes to
              classNameify merchandise for Customs as well as Central Excise
              duties. To make the codes more precise, the Customs and Central
              Excise authorities went on to add two more digits. This resulted
              in an eight-digit HSN classNameification.
            </p>
            <p className="hsnCodes">
              Almost all goods in India are classNameified using the HSN
              classNameification code, which facilitates the use of HSN numbers
              for calculations of the Goods and Service Tax (GST).  The number
              is currently being used to categorize goods to compute VAT (Value
              Added Tax).
            </p>
            <p className="hsnCodes">
              It's important to remember that the HSN number must be clearly
              mentioned on GST Invoices when preparing Tax Invoices for GST.
            </p>
            <p className="hsnCodes">
              Here's how the different HSN classNameifications, from two digits
              to eight digits, come into play in India:
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;The government gives some form of relief to small
              taxpayers with annual turnover up to Rs. 1.5 crore in any
              preceding financial year. After this move, there is no need for
              small taxpayers to mention HSN codes in their taxable income.
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Taxpayers with a turnover between Rs. 1.5 crore up to
              5.00 crore must mention only two digits of the HSN code.
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Taxpayers with a turnover that exceeds Rs. 5.00 crore
              must mention five digits of the HSN code.
            </p>
            <div className="extraDiv"></div>
            <h2>The Importance of HSN Under GST</h2>
            <p className="hsnCodes">
              The HSN system doesn't only help Customs agents-it also has
              important implications for GST.  It helps in making GST systematic
              and globally accepted.
            </p>
            <p className="hsnCodes">
              By using HSN codes, there is no need to upload a detailed
              description for each good falling under the GST. This allows for
              the automation of GST returns, saving time and money for the tax
              authority.
            </p>
            <p className="hsnCodes">
              Get in touch with our Freight Forwarding networking team here.
            </p>
            <div className="extraDiv"></div>
            <h2>What is SAC?</h2>
            <p className="hsnCodes">
              Service Accounting Code (SAC) is a unique code that is identical
              to the International HSN codes in many ways. SACs are issued by
              Central Board of Indirect Tax to classNameify each service so as
              to identify the applicable rate of tax on the respective services.
              These SACs are used at the time of generating invoices for the
              services rendered.
            </p>
            <div className="extraDiv"></div>
            <h2>Format of SAC</h2>
            <p className="hsnCodes">
              The SAC code is a unique 6 digit number that contains two number
              each for
            </p>

            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Chapter
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Heading
            </p>
            <p className="hsnCodes">
              <i className="fa fa-check-square" aria-hidden="true"></i>
              &nbsp;&nbsp;Sub-heading
            </p>
            <p className="hsnCodes">
              Let us understand this with an example, the SAC code for General
              construction services of mines and industrial plants is 995425
              that consists
            </p>
            <p className="hsnCodes">
              1)99 is the code that denotes the Chapter and remains constant
              throughout
            </p>
            <p className="hsnCodes">
              2)54 stands for civil engineering works (Heading)
            </p>
            <p className="hsnCodes">
              3)3.25 is for general construction services for plants and mines
              (Sub-heading)
            </p>
            <div className="extraDiv"></div>
            <h2>DIFFERANCE BETWEEN HSN AND SAC CODE</h2>
            <div className="extraDiv"></div>
            <table className="table table-bordered">
              <thead>
                <tr style={{ backgroundColor: "#32375e", color: "white" }}>
                  <th>Sr.No</th>
                  <th>Parameters</th>
                  <th>HSN</th>
                  <th>SAC</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>FULL FORM</td>
                  <td>HARMONSED SYSTEM OF NOMENCLATURE</td>
                  <td>SERVICES ACCOUNTING CODE</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>USED FOR</td>
                  <td>
                    HSN HELPS IN IDENTIFICATION OF GST RATES APPLICABLE TO GOODS
                  </td>
                  <td>
                    SAC HELPS IN IDENTIFICATION OF GST RATES APPLICABLE TO
                    SERVICES
                  </td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>STRUCTURE</td>
                  <td>6 DIGIT CODE</td>
                  <td>6 DIGIT CODE</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                HSN
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={tdsPageImage}
                  style={{ width: "100%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "TDS")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div style={{ textAlign: "center", marginTop: "3%" }}>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hsn;
