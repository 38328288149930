import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import "../../assets/css/topicDetail.css"
import { useParams } from 'react-router-dom';

const NewsArticle =()=>{

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    type: 'TDS' // Set default type value
  });
  const [data, setData] = useState([]); // Initialize as an empty array
  const [img, setImg] = useState([]); // Initialize as an empty array
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  // Fetch data from API
  const fetchData = async () => {
    try {
      const response = await axios.get('/apiblogs/homeBlogs');
      // console.log('Success:',response.data); // Log the entire response data
      const result = response.data; // Assuming JSON is the property containing the array
      setData(result.listTopics);
      setImg(result.listTopicImages);
    } catch (error) {
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('YOUR_API_ENDPOINT', formData);
      setSuccess('Form submitted successfully!');
      setSuccessModalVisible(true);
    } catch (error) {
      setError(error.message);
      setErrorModalVisible(true);
    }
  };

 

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Render component
  // if (error) return <p>{error}</p>;
  // if (data.length == 0) return <p></p>; // Display loading state

  const combinedData = data.map(d => {
    const imgItem = img.find(i => i.topicId === d.id);
    return imgItem ? { ...d, ...imgItem } : d;
  });

    return(
        <div>

   
<br/><br/><br/>
    <main class="container" >
        <div class="row">
        <h2>Latest Blogs</h2>
        { combinedData.map (blog => ( <article class="col-md-8 blog-post card">
               
               
                <img src={`data:image/png;base64,${blog.image}`} class="img-fluid" alt="Blog Image"  style={{marginTop:'0%', width:'100%'}}/>
                <p style={{fontWeight:'bold', fontSize:'24px'}}>{blog.description}</p>
                <p class="text-muted">Posted on {blog.date}</p>
                <a  class="btn btn-primary" href={`/BlogDetail/${blog.topicId}`}>Read More</a>
            </article> ))}
          <aside class="col-md-4" style={{marginTop:'-95%'}}>
                

                {/* <div class="p-3 mb-3 bg-light rounded card" style={{boxShadow:'15px'}}>
                    <h4>Categories</h4>
                    <ul class="list-unstyled">
                        <li><a href="#">1. Others</a></li>
                        <li><a href="#">2. Indirect Tax</a></li>
                        <li><a href="#">3. Direct Tax</a></li>
                        <li><a href="#">4. Compliance Due Date Chart</a></li>
                        <li><a href="#">5. Company Low</a></li>
                        <li><a href="#">6. Audit</a></li>
                    </ul>
                </div> */}

                {/* <div class="p-3 mb-3 bg-light rounded">
                    <h4>Archives</h4>
                    <ul class="list-unstyled">
                        <li><a href="#">September 2024</a></li>
                        <li><a href="#">August 2024</a></li>
                        <li><a href="#">July 2024</a></li>
                    </ul>
                </div> */}
            </aside>
        </div>
    </main>

   
  

        </div>
    )
}

export default NewsArticle;