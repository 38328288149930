import axios from "axios";
import React, { useState } from "react";
import icon1 from '../static/assets/img/icons/icon-1.png';
import icon2 from '../static/assets/img/icons/icon-2.png';
import icon3 from '../static/assets/img/icons/icon-3.png';
import icon4 from '../static/assets/img/icons/icon-4.png';
import icon5 from '../static/assets/img/icons/icon-5.png';
import icon6 from '../static/assets/img/icons/icon-6.png';
import tdsPageImage from '../static/img/tds img.jpg';
import ReCAPTCHA from 'react-google-recaptcha';
import "../assets/css/animate.min.css"
import "../assets/css/bootstrap.min.css";
import "../assets/css/datepicker.css";
import "../assets/css/font-awesome.min.css";
import "../assets/css/owl-carousel.css";
import "../assets/css/responsive.css";
import "../assets/css/style.css";
import "../assets/css/tdsStyle.css";
import "../static/assets/css/bootstrap.min.css";
// import "../static/assets/lib/owlcarousel/assets/owl.carousel.min.css";
import "../static/assets/css/all.min.css";
import "../static/assets/css/styleMin.css";
import "../static/assets/lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css";
import { Helmet, HelmetProvider} from  'react-helmet-async';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';


const TdsSoftware = () => {


	const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "TDS-O-SMART - Intelligent TDS Return Filing Software",
		"author": {
			"@type": "Person",
			"name": "Author Name"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/public/static/img/taxosmartLogo.jpg", 
		"articleBody": "Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments ",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/static/media/tds img.jpg" 
			}
		},
		"description": "Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments."
	}

	const [modal, setModal] = useState(false);

	const [formData, setFormData] = useState({
		type: '',
		name: '',
		email: '',
		mobile: ''
	});
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [successModalVisible, setSuccessModalVisible] = useState(false);
	const [errorModalVisible, setErrorModalVisible] = useState(false);
	const [captchaToken, setCaptchaToken] = useState(null);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value
		});
	};

	const handleCaptchaChange = (token) => {                  // new code for captcha
		setCaptchaToken(token);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!captchaToken) {                              // new code for captcha
			setError('Captcha is requred.');
			return;
		}

		const dataToSubmit = {					  // new code for captcha
			...formData,
			captchaToken,
		};

		axios.post('/apiCustomerDetail/', dataToSubmit)
			.then(response => {
				setSuccess("Success");
				setSuccessModalVisible(true); // Show success modal

				setError('');
				setFormData({
					type: '',
					name: '',
					email: '',
					mobile: ''
				});
				setCaptchaToken(null); // Reset captcha token
			})
			.catch(error => {
				setError("There was an error!");
				setErrorModalVisible(true);
			});
	};

	const handleCloseSuccess = () => setSuccessModalVisible(false);
	const handleCloseError = () => setErrorModalVisible(false);

	// Use to close the second popup box
	const [isVisible, setIsVisible] = useState(true);

	const closePop = () => {
		setIsVisible(false);
		window.location.reload();
	};

	return (
		<div>
		
	<HelmetProvider>
			<Helmet>
				<meta charset="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
				<title>TDS-O-SMART - Intelligent TDS Return Filing Software</title>
				<meta name="description" content="Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments." />
				<meta name="keywords" content="TDS-O-SMART, eTds, e-TDS Software , TDS Software, TDS Return Filing, 24Q, 26Q, 27Q, 27EQ, FVU, TDS Certificate, Form 16, Form 16A, TDS, TAX, Direct TAX, Salary return" />
                <meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" />
				
				{/* <!-- Open Graph meta tags for Facebook --> */}
				
				<meta property="og:title" content="TDS-O-SMART - Intelligent TDS Return Filing Software" />
				<meta property="og:description" content="Streamline your TDS return filing with TDS-O-SMART. Ensure 100% compliance with Indian Income Tax laws while effortlessly managing eTDS statements, correction statements, TDS certificates, and ePayments." />
				<meta property="og:image" content="https://www.taxosmart.com/static/img/taxosmartLogo.jpg" />
				<meta property="og:url" content="https://www.taxosmart.com/TdsSoftware" />
				<meta property="og:site_name" content="Taxosmart" />
				<meta property="og:type" content="website" />
				<meta name="robots" content=" index, follow" />
				{/* <!-- Twitter meta tags --> */}
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:url" content="https://www.example.com/tds-software" />
				<meta name="twitter:title" content="TDS-O-SMART - Intelligent TDS Return Filing Software" />
				<meta name="twitter:description" content="TDS-O-SMART is an intelligent TDS return filing software designed to ensure 100% compliance with Indian Income Tax laws. It simplifies TDS management by generating eTDS statements, correction statements, printing TDS certificates (Form 16/16A), and facilitating ePayments." />

				{/* <!-- Bing meta tags --> */}

				<meta name="robots" content="index, follow" />

				{/* <!-- Canonical link --> */}
				<link rel="canonical" href="https://www.taxosmart.com/TdsSoftware" />
			</Helmet>
			</HelmetProvider>
			
			<div className="container-fluid page-header py-5 mb-5 wow fadeIn"
				data-wow-delay="0.1s">
				<div className="container py-5">
					<h1 className="display-1 text-white animated slideInDown">TDS
						Software</h1>
					<div className="d-flex">
						<a data-bs-toggle="modal" data-bs-target="#demoModal" href=""
							data-bs-backdrop="false"
							className="btn btn-success py-3 px-5 mt-3 animated slideInDown tdsFont"
						>Try Now</a>&nbsp;&nbsp;&nbsp;&nbsp;



					</div>
				</div>
			</div>


			<div className="container-xxl py-5">
				<div className="container">
					<div className="text-center mx-auto mb-5 wow fadeInUp tdsColorFont"
						data-wow-delay="0.1s" >
						<h4 className=" serviceColor textDecoration" >Our Services</h4>
						<h1 className="display-5 mb-4 informationColor" >INFORMATION
							ABOUT TDS SOFTWARE</h1>
					</div>
				</div>
			</div>


			<main id="main">
				<section id="about" className="about tdsMarginTop" style={{ marginTop: '-7%' }}>

					<div className="container">
						<div className="row gx-0">

							<div className="col-lg-6 d-flex flex-column justify-content-center">
								<div className="content">

									<h2 className="tdsh2">What Is TDS Software?</h2>
									<p>TDS Software is an Intelligent TDS Return Filing Software
										built in state-of-the-art technology, complying with TDS/TCS
										prescribed as per Income Tax Laws of India. Its a complete TDS
										management software which ensures 100% TDS compliance by creating
										eTDS statements, correction statements, printing TDS certificates
										in Form 16/16A and ePayments</p>
									<p>Supports unlimited deductors / companies, deductees,
										employees</p>
									<p>Generates regular and revised returns for all forms and
										quarters from FY 2007-08 onwards</p>

								</div>
							</div>

							<div className="col-lg-6 d-flex align-items-center">
								<img src={tdsPageImage} alt="" className="tdsImage" />
							</div>

						</div>
					</div>
				</section>



			</main>
			<div className="container-xxl py-5">
				<div className="container">
					<div className="text-center mx-auto mb-5 wow fadeInUp tdsContainer"
						data-wow-delay="0.1s"
					>
						<h4 className=" featureColor" >Features</h4>
						<h1 className="display-5 mb-4 onlineColor" >Online
							Features</h1>
					</div>
					<div className="container-xxl py-5">
						<div className="container">
							<div className="row g-5">
								<div className="col-lg-6 wow fadeInUp tdsFile" data-wow-delay="0.1s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon1} alt="Icon" />
												<div className="ms-4">
													<h4>File Error Free TDS Returns in Just Few Minutes</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon2} alt="Icon" />
												<div className="ms-4">
													<h4>Highest Features at Lowest Price Guarantee</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon3} alt="Icon" />
												<div className="ms-4">
													<h4>Supports unlimited deductors / companies, deductees,
														employees</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInUp tdsFile" data-wow-delay="0.5s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon4} alt="Icon" />
												<div className="ms-4">
													<h4>In built more than 500 types of TRACES level checks,
														enabling early default warning</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon5} alt="Icon" />
												<div className="ms-4">
													<h4>Easy to Import/Export files from Ms-Excel</h4>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>



				</div>
			</div>

			<div className="container-xxl py-5">
				<div className="container">
					<div className="text-center mx-auto mb-5 wow fadeInUp fadeCss"
						data-wow-delay="0.1s"
					>
						<h4 className=" feaColor"  >Features</h4>
						<h1 className="display-5 mb-4 offColor" >Offline
							Features</h1>
					</div>
					<div className="container-xxl py-5">
						<div className="container">
							<div className="row g-5">
								<div className="col-lg-6 wow fadeInUp fadeEffect" data-wow-delay="0.1s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon1} alt="Icon" />
												<div className="ms-4">
													<h4>Supports unlimited deductors / companies, deductees,
														employees</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon2} alt="Icon" />
												<div className="ms-4">
													<h4>Generates regular and revised returns for all forms
														and quarters from FY 2007-08 onwards</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon3} alt="Icon" />
												<div className="ms-4">
													<h4>Preparing Lower deduction consumption report</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInUp fadeEffect" data-wow-delay="0.5s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon4} alt="Icon" />
												<div className="ms-4">
													<h4>In-built utility for Import / Export of data from
														MSExcel/ TDS/ Conso File / TXT file generated from other
														software</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon5} alt="Icon" />
												<div className="ms-4">
													<h4>Ease of use, simple navigation, Excel based with
														ready to use template</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-xxl py-5">
				<div className="container">
					<div className="text-center mx-auto mb-5 wow fadeInUp fadeCss"
						data-wow-delay="0.1s"
					>
						<h4 className=" feaColor" >Features</h4>
						<h1 className="display-5 mb-4 offColor" >Default
							Notifier</h1>
					</div>
					<div className="container-xxl py-5">
						<div className="container">
							<div className="row g-5">
								<div className="col-lg-6 wow fadeInUp fadeCss" data-wow-delay="0.1s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon1} alt="Icon" />
												<div className="ms-4">
													<h4>Generate Short Deduction Report, Short Payment
														Report</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon2} alt="Icon" />
												<div className="ms-4">
													<h4>Shows Detailed Report for Interest on Late Payment &
														Interest on Late Deduction</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon3} alt="Icon" />
												<div className="ms-4">
													<h4>Calculate Fees u/s 234E for Late Filing of TDS/TCS
														Return.</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInUp fadeCss" data-wow-delay="0.5s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon4} alt="Icon" />
												<div className="ms-4">
													<h4>All Reports can be Previewed / Printed or Exported
														to excel</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon5} alt="Icon" />
												<div className="ms-4">
													<h4>Generate report of invalid PAN, lower deduction
														certificate</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon6} alt="Icon" />
												<div className="ms-4">
													<h4>Compare challan's utilized amount with available
														balance amount</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>



			<div className="container-xxl py-5">
				<div className="container">
					<div className="text-center mx-auto mb-5 wow fadeInUp fadeCss"
						data-wow-delay="0.1s"
					>
						<h4 className="feaColor " >Features</h4>
						<h1 className="display-5 mb-4 offColor " >Other
							Features</h1>
					</div>
					<div className="container-xxl py-5">
						<div className="container">
							<div className="row g-5">
								<div className="col-lg-6 wow fadeInUp fadeCss" data-wow-delay="0.1s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon1} alt="Icon" />
												<div className="ms-4">
													<h4>Useful tools like Rate Chart, Income Tax Calculator
														etc. at your fingertips to help improve productivity.</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon2} alt="Icon" />
												<div className="ms-4">
													<h4>Record LOG of FVU files created</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon3} alt="Icon" />
												<div className="ms-4">
													<h4>Convert your regular FVU / Correction FVU
														/Consolidated TDS file into MS-Excel.</h4>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6 wow fadeInUp fadeCss" data-wow-delay="0.5s"
								>
									<div className="row g-4">
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon4} alt="Icon" />
												<div className="ms-4">
													<h4>Registered with Income Tax Department as ERA</h4>
												</div>
											</div>
										</div>
										<div className="col-12">
											<div className="d-flex align-items-start">
												<img className="flex-shrink-0"
													src={icon5} alt="Icon" />
												<div className="ms-4">
													<h4>Registered under TIN - NSDL's list of TDS softwares</h4>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="modal fade" id="demoModal">
				<div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
					<div className="modal-content sub-bg ">
						<div className="modal-header subs-header modal-xxl" style={{ width: '100%' }}>
							<h3 className="modal-title" style={{ fontSize: '34px' }}>TDS SOFTWARE</h3>
							<button type="button" className="close" data-bs-dismiss="modal"
								aria-hidden="true">&times;</button>
						</div>
						<div className="modal-body" >
							<div className="text-center">
								<img className="img-fluid" src={tdsPageImage}
									style={{ width: '70%', height: 'auto', marginTop: '-2%' }} />
							</div>
							<div className="heading-text text-center ">
								<h4 className="shadow p-3 mb-5">Ideal for Start-ups Going for
									Funding & Growing Business</h4>
							</div>
							<div className="">

								<form onSubmit={handleSubmit} style={{ border: '0px solid black' }}>
									<div className="form-group">
										<input className="form-control" style={{ display: 'none' }}
											type="text"
											id="type"
											name="type"
											value={formData.type = 'TDS'}
											onChange={handleChange}
											required
										/>
										<label htmlFor="name">Name</label>
										<input className="form-control"
											type="text"
											id="name"
											name="name"
											placeholder="Name"
											value={formData.name}
											onChange={handleChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="email">Email</label>
										<input
											type="email"
											id="email"
											name="email"
											placeholder="Email"
											className="form-control"
											value={formData.email}
											onChange={handleChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="mobile">Mobile</label>
										<input
											type="mobile"
											id="mobile"
											name="mobile"
											placeholder="Mobile"
											className="form-control"
											value={formData.mobile}
											onChange={handleChange}
											required
										/>
									</div>
									<div className="form-group" style={{ marginTop: '20px' }}>
										<ReCAPTCHA
											sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
											onChange={handleCaptchaChange} />
									</div>
									<div style={{ textAlign: 'center', marginTop: '3%' }}>
										<button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
									</div>
								</form>

							</div>
						</div>
					</div>
				</div>
			</div>


			{/* Success Modal */}

			<div>
				{isVisible && (
					<Modal show={successModalVisible} onHide={handleCloseSuccess}>
						<Modal.Header closeButton style={{ width: "100%" }}>
							<Modal.Title style={{ fontSize: "28px" }}>
								Successfull
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Thank you for choosing Taxosmart,We Request you to confirm your
							email address. Our representative will soon contact you.
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={closePop}>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
				)}
			</div>

			{/* Error Modal */}
			<div>
				{isVisible && (
					<Modal
						show={errorModalVisible}
						onHide={handleCloseError}

					>
						<Modal.Header closeButton style={{ width: "100%" }}>
							<Modal.Title style={{ fontSize: "28px" }}>Something Went Wrong !</Modal.Title>
						</Modal.Header>
						<Modal.Body>{error}</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								id="closeBtn"

								onClick={closePop}>
								Close
							</Button>
							{/* <Button variant="primary" id="closeBtn" onClick={closePop}>
                CLOSE
              </Button> */}
						</Modal.Footer>
					</Modal>
				)}
			</div>

			<Helmet>
  <script type="application/ld+json">
    {JSON.stringify(structuredData)}
  </script>
</Helmet>

		</div >




	)


}
export default TdsSoftware;