import axios from "axios";
import React, { useState } from "react";
import "../assets/css/gstInvoice.css";
import iSmartImage from "../static/img/i-smart.png";
import icon1 from "../static/assets/img/icons/icon-1.png";
import icon2 from "../static/assets/img/icons/icon-2.png";
import icon3 from "../static/assets/img/icons/icon-3.png";
import icon4 from "../static/assets/img/icons/icon-4.png";
import icon5 from "../static/assets/img/icons/icon-5.png";
import icon6 from "../static/assets/img/icons/icon-6.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from "react-helmet";

const GstInvoice = () => {

  // Structerd Data for SEO 
  const structuredData = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "GST Invoice Accounting Inventering",
		"author": {
			"@type": "Person",
			"name": "Taxosmart"
		},
		"datePublished": "2024-10-21",
		"image": "https://taxosmart.com/static/img/i-smart.png", 
		"articleBody": "A GST invoice is a document that contains all relevant details of a business transaction and both parties involved. It must comprise the product name, description, quantity, supplier and purchaser details, terms of sale, rate charged, discounts, and more.",
		"publisher": {
			"@type": "Organization",
			"name": "TAXOSMART",
			"logo": {
				"@type": "ImageObject",
				"url": "https://taxosmart.com/public/static/img/taxosmartLogo.jpg" 
			}
		},
		"description": "A GST invoice is a document that contains all relevant details of a business transaction and both parties involved. It must comprise the product name, description, quantity, supplier and purchaser details, terms of sale, rate charged, discounts, and more.",
	  "keyword" : "GST Billing Software, Goods and Services Tax, GST Bill, Billing Software, GST software, GST App, GST solution, GST website, GST invoice"
  }

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    email: "",
    mobile: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleCaptchaChange = (token) => {                  // new code for captcha
    setCaptchaToken(token);
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaToken) {                              // new code for captcha
      setError('Captcha is requred.');
      return;
    }

    const dataToSubmit = {					  // new code for captcha
      ...formData,
      captchaToken,
    };


    axios
      .post("/apiCustomerDetail/", dataToSubmit)
      .then((response) => {
        setSuccess("Success");
        setSuccessModalVisible(true); // Show success modal

        setError("");
        setFormData({
          type: "",
          name: "",
          email: "",
          mobile: "",
        });
        setCaptchaToken(null); // Reset captcha token
      })
      .catch((error) => {
        setError("There was an error!");
        setErrorModalVisible(true);
      });
  };

  const handleCloseSuccess = () => setSuccessModalVisible(false);
  const handleCloseError = () => setErrorModalVisible(false);
  // Use to close the second popup box
  const [isVisible, setIsVisible] = useState(true);

  const closePop = () => {
    setIsVisible(false);
    window.location.reload();
  };

  return (

    <div>
       <html>
    <body>
    <head>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <title>GST Invoice, Accounting And Inventory Management Software</title>
        <meta name="description" content="A GST invoice is a document that contains all relevant details of a business transaction and both parties involved. It must comprise the product name, description, quantity, supplier and purchaser details, terms of sale, rate charged, discounts, and more." />
        <meta name="keywords" content="GST Billing Software, Goods and Services Tax, GST Bill, Billing Software, GST software, GST App, GST solution, GST website, GST invoice." />
        <meta name="author" content="Taxosmart" />

        {/* <!-- Open Graph (for Facebook) --> */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="GST Invoice, Accounting And Inventory Management Software" />
        <meta property="og:description" content="Discover a comprehensive GST invoice solution that simplifies your billing process while ensuring compliance with GST regulations." />
        <meta property="og:url" content="https://www.taxosmart.com/gstInvoice" />
        <meta property="og:site_name" content="Taxosmart" />

        {/* <!-- Twitter Card --> */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="GST Invoice, Accounting And Inventory Management Software" />
        <meta name="twitter:description" content="Discover a comprehensive GST invoice solution that simplifies your billing process while ensuring compliance with GST regulations." />
        <meta name="twitter:url" content="https://www.taxosmart.com/gstInvoice" />

        {/* <!-- Canonical Link --> */}
        <link rel="canonical" href="https://www.taxosmart.com/gstInvoice" />

        {/* <!-- Bing and other robots --> */}
        <meta name="robots" content="index, follow" />

      </Helmet>
      </head>
      <section
        id="hero"
        className="hero d-flex align-items-center wow fadeIn "
        data-wow-delay="0.1s"
      >
        <div className="container top0">
          <div className="Topbox" style={{ marginTop: "-5%" }}>
            <div className="topcontent">
              <h1 className="InfoColor">GST Invoice Accounting Inventering</h1>
              <p className="effort">
                A GST invoice is a document which is supposed to contain all the
                relevant details of a business transaction and both the parties
                involved.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#demoModal"
                    data-bs-backdrop="false"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span className="getTouch">Get In Touch</span>
                    <i className="bi bi-arrow-right white"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="topimg" data-aos="zoom-out" data-aos-delay="200">
              <img
                src={iSmartImage}
                className="img-fluid gstInvoiceImageCss"
                alt=""
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </section>

      <div class="container-xxl py-5 pb-0" id="maincontainer">
        <div class="container">
          <div
            class="text-center mx-auto mb-5 wow fadeInUp ourService"
            data-wow-delay="0.1s"
          >
            <h4 class="ourServiceColor">Our Services</h4>
            <h1 class=" mb-4 gstFont">
              INFORMATION ABOUT GST INVOICE,ACCOUNTING AND INVENTORY MANAGEMENT
            </h1>
          </div>
        </div>
      </div>

      <section id="about" class="about gstTopClass">
        <div class="container">
          <div class="row gx-0">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <div class="content">
                <h2 className="gstColorInvoice">What Is GST Invoice?</h2>
                <p>
                  A GST invoice is a document which is supposed to contain all
                  the relevant details of a business transaction and both the
                  parties involved. It must comprise of the product name,
                  description, quantity, details of the supplier and the
                  purchaser, terms of sale, rate charged, discounts, etc.
                </p>
              </div>
            </div>

            <div class="col-lg-6 d-flex align-items-center">
              <img src={iSmartImage} alt="" class="gstImage" />
            </div>
          </div>
        </div>
      </section>

      <div class="container-xxl py-5 pb-0" id="maincontainer">
        <div class="container">
          <div
            class="text-center mx-auto mb-5 wow fadeInUp fadeCss"
            data-wow-delay="0.1s"
          >
            <h4 class=" stepsColor">Steps</h4>
            <h1 class="gstFont mb-4 stepsInvoiceColor">
              Steps For Invoice Managing Process
            </h1>
          </div>
          <div class="container-xxl py-5" id="maincontainer">
            <div class="container">
              <div class="row g-5">
                <div
                  class="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon1} alt="Icon" />
                        <div class="ms-4">
                          <h3>Synchronize invoices/challans by: </h3>
                          <p class="mb-0">
                            Integrating existing ERP with our solution OR
                            Importing Excel files{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon2} alt="Icon" />
                        <div class="ms-4">
                          <h3>Search Invoices by GSTIN:</h3>
                          <p class="mb-0">Customer Name, Invoice No</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon3} alt="Icon" />
                        <div class="ms-4">
                          <h3>Input the additional:</h3>
                          <p class="mb-0">transporter details</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon4} alt="Icon" />
                        <div class="ms-4">
                          <h3>Create, Modify:</h3>
                          <p class="mb-0">Print e-Way bills</p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon5} alt="Icon" />
                        <div class="ms-4">
                          <h3>Input the additional:</h3>
                          <p class="mb-0">transporter details</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-xxl py-5" id="maincontainer">
        <div class="container">
          <div
            class="text-center mx-auto mb-5 wow fadeInUp fadeCss"
            data-wow-delay="0.1s"
          >
            <h4 class=" stepsColor">Steps</h4>
            <h1 class=" mb-4 gstFont stepsInvoiceColor">
              Steps For Invoice Generation Process
            </h1>
          </div>
          <div class="container-xxl py-5" id="maincontainer">
            <div class="container">
              <div class="row g-5">
                <div
                  class="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.1s"
                >
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon1} alt="Icon" />
                        <div class="ms-4">
                          <h3>Invoicing : </h3>
                          <p class="mb-0">1.Generate GST compliant invoices </p>
                          <p class="mb-0">
                            2.Download, Print and Share invoices with your
                            custom{" "}
                          </p>
                          <p class="mb-0">
                            3.Maintain items catalog and contact directory
                            automatically{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon2} alt="Icon" />
                        <div class="ms-4">
                          <h3> File your GST returns in a single click :</h3>
                          <p class="mb-0">
                            Save time in filing GST Returns by using already
                            prepared invoices on Tax-O-Smart{" "}
                          </p>
                          <p class="mb-0">
                            Identify errors before uploading to GSTN{" "}
                          </p>
                          <p class="mb-0">
                            Avoid penalties & claim correct Input Tax Credits{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon3} alt="Icon" />
                        <div class="ms-4">
                          <h3> Payments Tracking:</h3>
                          <p class="mb-0">
                            Record payments for invoices created in the system
                          </p>
                          <p class="mb-0">
                            Track payments easily & get paid on time
                          </p>
                          <p class="mb-0">
                            Associate a single payment with multiple invoices
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-6 wow fadeInUp fadeCss"
                  data-wow-delay="0.5s"
                >
                  <div class="row g-4">
                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon4} alt="Icon" />
                        <div class="ms-4">
                          <h3>Stock Management </h3>
                          <p class="mb-0">
                            1.Manage stock for items you generate invoices for{" "}
                          </p>
                          <p class="mb-0">
                            2.Automatically update stock while you create
                            invoices{" "}
                          </p>
                          <p class="mb-0">
                            3.Track available quantity of items in your stock{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="d-flex align-items-start">
                        <img class="flex-shrink-0" src={icon5} alt="Icon" />
                        <div class="ms-4">
                          <h3>
                            {" "}
                            Make effective business decisions based on real time
                            information{" "}
                          </h3>
                          <p class="mb-0">
                            1.Share invoices with customers & track payments
                          </p>
                          <p class="mb-0">
                            2.Track inventory & place orders with vendors on
                            time
                          </p>
                          <p class="mb-0">
                            3.Get real time business view of purchases, sales &
                            inventory
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="demoModal">
        <div className="modal-dialog shadow-lg p-3 mb-5 rounded modal-xxl">
          <div className="modal-content sub-bg ">
            <div
              className="modal-header subs-header modal-xxl"
              style={{ width: "100%" }}
            >
              <h3 className="modal-title" style={{ fontSize: "34px" }}>
                GST Invoice Accounting Inventering
              </h3>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={iSmartImage}
                  style={{ width: "70%", height: "auto", marginTop: "-2%" }}
                />
              </div>
              <div className="heading-text text-center ">
                <h4 className="shadow p-3 mb-5">
                  Ideal for Start-ups Going for Funding & Growing Business
                </h4>
              </div>
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  style={{ border: "0px solid black" }}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      style={{ display: "none" }}
                      type="text"
                      id="type"
                      name="type"
                      value={(formData.type = "GST Invoice")}
                      onChange={handleChange}
                      required
                    />
                    <label htmlFor="name">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input
                      type="mobile"
                      id="mobile"
                      name="mobile"
                      placeholder="Mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: '20px' }}>
                    <ReCAPTCHA
                      sitekey="6Ld8XugUAAAAALsjUNeDDt0KHeG4HrMKPfL72sFt"
                      onChange={handleCaptchaChange} />
                  </div>
                  <div style={{ textAlign: 'center', marginTop: '3%' }}>
                    <button type="submit" className="btn btn-success" disabled={!captchaToken}>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}

      <div>
        {isVisible && (
          <Modal show={successModalVisible} onHide={handleCloseSuccess}>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Successfull
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Thank you for choosing Taxosmart,We Request you to confirm your
              email address. Our representative will soon contact you.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closePop}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>

      {/* Error Modal */}
      <div>
        {isVisible && (
          <Modal
            show={errorModalVisible}
            onHide={handleCloseError}
            style={{ width: "100%" }}
          >
            <Modal.Header>
              <Modal.Title style={{ fontSize: "28px" }}>
                Something Went Wrong !
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{error}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" id="closeBtn" onClick={closePop}>
                Close
              </Button>
              {/* <Button variant="primary" id="closeBtn" onClick={closePop}>
                CLOSE
              </Button> */}
            </Modal.Footer>
          </Modal>
        )}
      </div>
      <Helmet>
  <script type="application/ld+json">
    {JSON.stringify(structuredData)}
  </script>
</Helmet>
</body>
</html>
      
    </div>
  );
};
export default GstInvoice;
